import {actionTypes} from './actions';

const loaderReducer = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.LOADER_ON:
            return state + 1;
        case actionTypes.LOADER_OFF:
            return state - 1;
        default:
            return state;
    }
};

export default loaderReducer;
