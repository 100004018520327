import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {styled} from '@mui/system';

import VideoStream from './components/VideoStream';
import AudioStream from './components/AudioStream';
import ErrorMessage from './components/ErrorMessage';
import ControlButtons from './components/ControlButtons';
import GuestUserName from './components/GuestUserName';

const Styles = styled('div')(() => ({
    '.lobby-container': {
        width: '100vw',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    '.user-media': {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2)',
        padding: '16px 22px',
        borderRadius: 6
    }
}));

export function Lobby({isMediaCaptureError, capture, user, getLocalStream}) {
    const {broadcastId} = useParams();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (!capture) getLocalStream();
    }, []);

    return (
        <Styles>
            <div className="lobby-container">
                <div className="user-media">
                    <VideoStream />
                    <AudioStream />
                </div>
                {isMediaCaptureError && <ErrorMessage />}
                {broadcastId && !user && (
                    <GuestUserName onChange={({target: {value}}) => setUserName(value)} value={userName} />
                )}
                <ControlButtons isGuestUser={Boolean(broadcastId && !user)} userName={userName} />
            </div>
        </Styles>
    );
}

Lobby.propTypes = {
    isMediaCaptureError: PropTypes.bool,
    capture: PropTypes.object,
    user: PropTypes.object,
    getLocalStream: PropTypes.func
};
