import {call, put, takeLatest, takeEvery, takeLeading} from 'redux-saga/effects';
import {toast} from 'react-toastify';

const handleTypes = {
    takeLatest,
    takeEvery,
    takeLeading
};

const defaultOptions = {
    handleType: 'takeLatest',
    successMessage: '',
    errorNotification: true,
    getError: (err) => err
};

export const handleAsyncActionSaga = (actionType, handler, options = {}) => {
    const config = {
        ...defaultOptions,
        ...options
    };

    return handleTypes[config.handleType].apply(null, [
        actionType.REQUEST,
        function* ({payload, onSuccess}) {
            try {
                const result = yield call(handler, payload);

                yield put({
                    type: actionType.SUCCESS,
                    payload: result
                });

                if (config.successMessage) toast.success(config.successMessage);

                onSuccess();
            } catch (err) {
                const error = config.getError(err);

                yield put({
                    type: actionType.FAILURE,
                    error
                });
            }
        }
    ]);
};
