import React, {useRef, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import hark from 'hark';
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon
} from '@mui/icons-material';

import {MEDIA_TYPES} from 'constants';
import {calculateGridSize, normalizeStreamMember} from 'services/helpers';
import {broadcastSelectors} from 'store/broadcast';

import VideoAvatar from '../../../Avatar';
import MoreMenu from './components/MoreMenu';

import styles from './Stream.module.scss';

export function Stream({stream, activeStreamsCount}) {
    const isHost = useSelector(broadcastSelectors.getBroadcastHostStatus);
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [isVideoPaused, setIsVideoPaused] = useState(false);
    const [isActiveSpeaker, setIsActiveSpeaker] = useState(false);
    const [isAudioPaused, setIsAudioPaused] = useState(false);
    const [isScreenShared, setIsScreenShared] = useState(false);
    const videoEl = useRef();
    const audioEl = useRef();

    const {width, height} = calculateGridSize(activeStreamsCount);

    const enableTracks = useCallback(
        (newVideoTrack, newAudioTrack) => {
            if (audioTrack === newAudioTrack && videoTrack === newVideoTrack) return;

            if (newAudioTrack) {
                const stream = new MediaStream();

                stream.addTrack(newAudioTrack);
                audioEl.current.srcObject = stream;

                audioEl.current.play().catch((error) => console.warn('audioEl.play() failed:%o', error));
            } else {
                audioEl.current.srcObject = null;
            }

            if (newVideoTrack) {
                const stream = new MediaStream();
                stream.addTrack(newVideoTrack);
                videoEl.current.srcObject = stream;
                videoEl.current.id = newVideoTrack.id;

                videoEl.current.onplay = () => {
                    audioEl.current.play().catch((error) => console.warn('audioEl.play() failed:%o', error));
                };

                videoEl.current.play().catch((error) => console.warn('videoEl.play() failed:%o', error));
            } else {
                videoEl.current.srcObject = null;
            }
        },
        [audioTrack, videoTrack]
    );

    const getTracks = useCallback(() => {
        const consumersArray = stream?.consumers;

        if (!consumersArray) return;

        const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.audio);
        const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.video);
        const shareConsumer = consumersArray.find(
            (consumer) => consumer.appData.type === MEDIA_TYPES.consumerScreenShare
        );

        const videoTrack = shareConsumer ? shareConsumer?.track : videoConsumer?.track;
        const audioTrack = audioConsumer ? audioConsumer.track : null;

        setIsVideoPaused(!videoConsumer);
        setIsAudioPaused(audioConsumer?.paused); // TODO: remove question mark and need investigation
        setIsScreenShared(!!shareConsumer);

        setVideoTrack(videoTrack);
        setAudioTrack(audioTrack);

        enableTracks(videoTrack, audioTrack);
    }, [stream, enableTracks]);

    useEffect(() => {
        const videoRef = videoEl.current;

        getTracks();
        return () => {
            if (videoRef) {
                videoRef.oncanplay = null;
                videoRef.onplay = null;
                videoRef.onpause = null;
            }
        };
    }, [getTracks]);

    useEffect(() => {
        getTracks();
    }, [stream, getTracks]);

    useEffect(() => {
        const consumersArray = stream?.consumers;

        if (!stream || !consumersArray) {
            return;
        }

        const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.audio);
        const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.video);
        const shareConsumer = consumersArray.find(
            (consumer) => consumer.appData.type === MEDIA_TYPES.consumerScreenShare
        );

        setIsVideoPaused(!videoConsumer);
        setIsScreenShared(!!shareConsumer);

        if (audioConsumer) {
            setIsAudioPaused(!!stream?.pausedConsumers?.[audioConsumer.id]);
        }
    }, [stream]);

    useEffect(() => {
        if (audioTrack) {
            const mediaStream = new MediaStream();

            mediaStream.addTrack(audioTrack);

            const speechEvents = hark(mediaStream, {});

            speechEvents.on('speaking', () => {
                if (activeStreamsCount > 1) setIsActiveSpeaker(true);
            });

            speechEvents.on('stopped_speaking', () => setIsActiveSpeaker(false));
        }
    }, [activeStreamsCount, audioTrack]);

    const streamMember = normalizeStreamMember(stream);

    const MicIndicator = () => {
        const IconName = isAudioPaused ? MicOffIcon : MicIcon;
        return <IconName className={styles.indicatorIcon} />;
    };
    const VideoIndicator = () => {
        const IconName = stream?.consumers?.length < 2 ? VideocamOffIcon : VideocamIcon;
        return <IconName className={styles.indicatorIcon} />;
    };
    const ScreenShareIndicator = () => {
        const IconName = !isScreenShared ? StopScreenShareIcon : ScreenShareIcon;
        return <IconName className={styles.indicatorIcon} />;
    };

    return (
        <div className={styles.videoWrapper} style={{width, height}}>
            {isActiveSpeaker && <div className={styles.isActiveSpeaker} />}
            <video
                className={classNames(styles.video, {
                    [styles.videoPaused]: isVideoPaused,
                    [styles.screenshare]: isScreenShared
                })}
                ref={videoEl}
                autoPlay
                playsInline
                muted
                controls={false}
            />
            {isVideoPaused && <VideoAvatar text={'name'} member={streamMember} />}
            <audio ref={audioEl} className={styles.audioStream} autoPlay muted={false} controls={false} />
            <div className={styles.userInfo}>
                <MicIndicator />
                <VideoIndicator />
                <ScreenShareIndicator />
                <p className={styles.userName}>{stream?.user?.displayName}</p>
            </div>
            {isHost && <MoreMenu stream={stream} />}
        </div>
    );
}

Stream.propTypes = {
    stream: PropTypes.object.isRequired,
    activeStreamsCount: PropTypes.number.isRequired
};

Stream.defaultProps = {
    stream: {}
};
