import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Snackbar} from '@mui/material';

import SelfVideo from './components/SelfVideo';
import VideoRoom from './components/VideoRoom';
import Buttons from './components/Buttons';
import Spinner from './components/Spinner/Spinner';
import FullScreenButton from './components/FullScreenButton';

import GuestQueue from './components/GuestQueue';
import HostControls from './components/HostControls';

import {useCreateBroadcast} from 'services/hooks';
import {SUCCESS_SNACKBAR_MESSAGES} from 'constants';

import styles from './Broadcast.module.scss';

export function Broadcast({videoCapture, user, client, broadcastData, broadcastLayout, isHost, isSelfStreamAdded}) {
    const navigate = useNavigate();
    const [fullScreen, setFullScreen] = useState(false);
    const [successSnackbarOpened, toggleSuccessSnackbar] = useState(false);
    const {loading} = useCreateBroadcast();

    useEffect(() => {
        if (!videoCapture) {
            navigate(-1);
        }
    }, []);

    const toggleFullScreen = (value) => setFullScreen(value);

    if (loading || !client) return <Spinner />;

    return (
        <>
            <div
                className={classNames(styles.videoContainer, {
                    [styles.fullScreen]: fullScreen
                })}
            >
                {!!videoCapture && isSelfStreamAdded && (
                    <SelfVideo videoCapture={videoCapture} user={user} streams={broadcastLayout?.streams} />
                )}
                <VideoRoom streams={broadcastLayout?.streams} isFullScreen={fullScreen} />
                {broadcastData?.mediaSession && <Buttons withCallEndIcon={false} />}
                <FullScreenButton fullScreen={fullScreen} setFullScreen={toggleFullScreen} />
            </div>
            {isHost && <HostControls toggleSuccessSnackbar={toggleSuccessSnackbar} />}
            {isHost && <GuestQueue />}
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={successSnackbarOpened}
                onClose={() => toggleSuccessSnackbar(false)}
                autoHideDuration={5000}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                className="snackbar"
                message={<span id="message-id">{SUCCESS_SNACKBAR_MESSAGES.successfullyCopiedBroadcastLink}</span>}
            />
        </>
    );
}

Broadcast.propTypes = {
    videoCapture: PropTypes.object,
    user: PropTypes.object,
    client: PropTypes.object,
    broadcastData: PropTypes.object,
    broadcastLayout: PropTypes.object,
    isHost: PropTypes.bool,
    isSelfStreamAdded: PropTypes.bool
};
