export const MEDIA_TYPES = {
    audio: 'audio',
    video: 'video',
    shareScreen: 'shareScreen',
    consumerScreenShare: 'screenshare'
};

export const MEDIA_DEVICES_KINDS = {
    audioinput: 'audioinput',
    videoinput: 'videoinput',
    audiooutput: 'audiooutput',
    videooutput: 'videooutput'
};

export const GET_MEDIA_CAPTURE_ERROR_MESSAGE =
    'Please check browser access to your outputs or they are used by another software.';

export const BUTTON_TYPES = {
    sound: 'sound',
    video: 'video',
    shareScreen: 'shareScreen',
    stopCall: 'stopCall'
};
