import {combineReducers} from 'redux';
import userReducer from './user';
import loaderReducer from './loader';
import lobbyReducer from './lobby';
import broadcastReducer from './broadcast';
import broadcastsListReducer from './broadcastsList';

const appReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    lobby: lobbyReducer,
    broadcast: broadcastReducer,
    broadcastsList: broadcastsListReducer
});

export default appReducer;
