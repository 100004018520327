import React from 'react';
import {useNavigate} from 'react-router-dom';

import Auth from 'components/Auth';
import NavTabs from './components/NavTabs';

import styles from './Header.module.scss';

export function Header() {
    const navigate = useNavigate();
    const onLogoClick = () => navigate('/');

    return (
        <header className={styles.header}>
            <div className={styles.inner}>
                <h2 className={styles.headerTitle} onClick={onLogoClick}>
                    Digital Joy
                </h2>
                <NavTabs />
            </div>
            <Auth />
        </header>
    );
}
