import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Stream from './components/Stream';

import styles from './VideoRoom.module.scss';

export const VideoRoom = ({streams, isFullScreen}) => {
    const activeStreamsCount = streams.length;

    if (!activeStreamsCount) return null;

    return (
        <div
            className={classNames(styles.playbacksList, {
                [styles.hidden]: !activeStreamsCount,
                [styles.isFullScreen]: isFullScreen
            })}
        >
            {streams.map((stream) => (
                <Stream key={stream.id} stream={stream} activeStreamsCount={activeStreamsCount} />
            ))}
        </div>
    );
};

VideoRoom.propTypes = {
    streams: PropTypes.arrayOf(PropTypes.object),
    dominantSpeakerOrder: PropTypes.arrayOf(PropTypes.object),
    isFullScreen: PropTypes.bool
};

VideoRoom.defaultProps = {
    streams: []
};
