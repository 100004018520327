export const VIDEO_ROOM_EVENTS = {
    STREAM_JOINED: 'stream_joined',
    STREAM_LEFT: 'stream_left',
    PRODUCER_TOGGLE: 'producer_toggle',
    LAYOUT_CHANGED: 'layout_changed',
    BROADCAST_DESTROYED: 'broadcast_destroyed',
    STREAM_KICKED: 'stream_kicked',
    RECORDING_STARTED: 'recording_started',
    RECORDING_STOPPED: 'recording_stopped'
};

export const MS_EVENTS = {
    TRANSPORT: {
        CREATED: 'transport_created',
        DESTROYED: 'transport_destroyed'
    },
    CONSUMER: {
        CREATED: 'consumer_created',
        DESTROYED: 'consumer_destroyed',
        PAUSED: 'consumer_paused',
        RESUMED: 'consumer_resumed'
    },
    PRODUCER: {
        CREATED: 'producer_created',
        DESTROYED: 'producer_destroyed',
        PAUSED: 'producer_paused',
        RESUMED: 'producer_resumed'
    }
};

export const PARTICIPANT_TYPES = {
    host: 'host',
    guest: 'guest',
    mixer: 'mixer'
};

export const SIGNALING_TRANSPORT_STATES = {
    DISCONNECTED: 'disconnected',
    CONNECTED: 'connected',
    AUTHENTICATED: 'authenticated'
};

export const STREAM_AUTHENTICATE_RESULT = {
    CREATED: 'stream_created',
    RESTORED: 'stream_restored'
};

export const BROADCAST_LAYOUT_STREAM_TYPES = {
    IngressRTMP: 'ingress_rtmp',
    WebRTC: 'webrtc',
    S3: 'S3'
};

export const BROADCAST_HOST_ACTIONS = {
    REMOVE_STREAM: 'remove_stream',
    TOGGLE_AUDIO: 'toggle_audio',
    TOGGLE_VIDEO: 'toggle_video',
    TOGGLE_SCREEN_SHARE: 'toggle_screen_share',
    KICK: 'kick'
};
