import React from 'react';

import {GET_MEDIA_CAPTURE_ERROR_MESSAGE} from 'constants';
import {Styles} from './ErrorMessage.styles';

export function ErrorMessage() {
    return (
        <Styles className="wrapper">
            <p className="text">{GET_MEDIA_CAPTURE_ERROR_MESSAGE}</p>
        </Styles>
    );
}
