import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Button} from '@mui/material';

import styles from './StreamUrlInput.module.scss';

export function StreamUrlInput({value, onChange, streamType, onButtonClick}) {
    return (
        <div className={styles.container}>
            <TextField
                value={value}
                variant="outlined"
                type="text"
                label={`${streamType.replace('_', ' ')} stream URL`}
                margin="normal"
                className={styles.streamUrlInput}
                onChange={onChange}
            />
            <Button onClick={onButtonClick} variant="outlined" className={styles.addButton} disabled={!value}>
                {streamType === 'PUSH_RTMP' ? 'Push' : 'Add'} stream
            </Button>
        </div>
    );
}

StreamUrlInput.propTypes = {
    value: PropTypes.string,
    streamType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onButtonClick: PropTypes.func.isRequired
};
