import {styled} from '@mui/system';

export const Styles = styled('div')(({theme}) => ({
    '.wrapper': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 22px',
        marginTop: 20
    },
    '.button': {
        height: 54,
        fontSize: 14,
        lineHeight: '120%',
        textTransform: 'uppercase',
        margin: '0px 10px',
        boxShadow: 'none'
    },
    '.buttonOutlined': {
        border: `1px solid ${theme.palette.grey.A150}`
    }
}));
