import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {BROADCAST_HOST_ACTIONS, MEDIA_TYPES} from 'constants';

import styles from './MoreMenu.module.scss';

const options = [
    {label: 'Remove from layout', value: BROADCAST_HOST_ACTIONS.REMOVE_STREAM},
    {label: 'Toggle audio', value: BROADCAST_HOST_ACTIONS.TOGGLE_AUDIO},
    {label: 'Toggle video', value: BROADCAST_HOST_ACTIONS.TOGGLE_VIDEO},
    {label: 'Toggle screen share', value: BROADCAST_HOST_ACTIONS.TOGGLE_SCREEN_SHARE}
    // {label: 'Kick', value: BROADCAST_HOST_ACTIONS.KICK}
];

const ITEM_HEIGHT = 48;

export function MoreMenu({client, broadcast, removeStreamFromLayout, stream, isSelfStream}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleToggleRemoteProducer = (producerType) => broadcast.toggleRemoteProducer(stream.id, producerType);

    const handleClose = useCallback(
        (value) => () => {
            switch (value) {
                case BROADCAST_HOST_ACTIONS.REMOVE_STREAM:
                    removeStreamFromLayout({
                        streamId: stream?.id || client.sessionId,
                        isSelfStream
                    });
                    break;
                case BROADCAST_HOST_ACTIONS.TOGGLE_AUDIO:
                    handleToggleRemoteProducer(MEDIA_TYPES.audio);
                    break;
                case BROADCAST_HOST_ACTIONS.TOGGLE_VIDEO:
                    handleToggleRemoteProducer(MEDIA_TYPES.video);
                    break;
                case BROADCAST_HOST_ACTIONS.TOGGLE_SCREEN_SHARE:
                    handleToggleRemoteProducer(MEDIA_TYPES.consumerScreenShare);
                    break;
                default:
                    setAnchorEl(null);
            }
            setAnchorEl(null);
        },
        []
    );

    return (
        <div className={styles.menuWrapper}>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'
                    }
                }}
            >
                {options
                    .filter((option) => {
                        if (isSelfStream) {
                            return option.value === BROADCAST_HOST_ACTIONS.REMOVE_STREAM;
                        }

                        return true;
                    })
                    .map(({label, value}) => {
                        return (
                            <MenuItem key={value} onClick={handleClose(value)}>
                                {label}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </div>
    );
}

MoreMenu.propTypes = {
    stream: PropTypes.object,
    client: PropTypes.object,
    broadcast: PropTypes.object,
    removeStreamFromLayout: PropTypes.func,
    isSelfStream: PropTypes.bool
};
