import {ControlButtons} from './ControlButtons';
import {connect} from 'react-redux';
import {lobbySelectors, lobbyActions} from 'store/lobby';
import {userActions} from 'store/user';
import {broadcastActions} from 'store/broadcast';

export default connect(
    (state) => ({
        capture: lobbySelectors.getVideoCapture(state)
    }),
    {
        toggleSelfVideo: lobbyActions.toggleSelfVideo,
        toggleSelfAudio: lobbyActions.toggleSelfAudio,
        broadcastGuestAuth: broadcastActions.broadcastGuestAuth,
        createBroadcast: broadcastActions.createBroadcast,
        authLoginSuccess: userActions.authLoginSuccess
    }
)(ControlButtons);
