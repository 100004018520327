import {connect} from 'react-redux';
import {Lobby} from './Lobby';
import {lobbySelectors, lobbyActions} from 'store/lobby';
import {userSelectors} from 'store/user';

export default connect(
    (state) => ({
        isMediaCaptureError: lobbySelectors.getMediaCaptureError(state),
        capture: lobbySelectors.getVideoCapture(state),
        user: userSelectors.getUserData(state)
    }),
    {getLocalStream: lobbyActions.getLocalStream}
)(Lobby);
