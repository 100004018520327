import {createAsyncActionTypes, createAsyncAction} from 'services/redux';

const SERVICE_NAME = 'BROADCASTS_LIST';

export const actionTypes = {
    GET_ALL_BROADCASTS: createAsyncActionTypes(SERVICE_NAME, 'Get all'),
    GET_USER_BROADCASTS: createAsyncActionTypes(SERVICE_NAME, 'Get user list'),
    CREATE_NEW_BROADCAST: createAsyncActionTypes(SERVICE_NAME, 'Create new broadcast'),
    DELETE_BROADCAST: createAsyncActionTypes(SERVICE_NAME, 'Delete broadcast')
};

export const actions = {
    getAllBroadcasts: createAsyncAction(actionTypes.GET_ALL_BROADCASTS, 'all broadcasts list'),
    getUsersBroadcasts: createAsyncAction(actionTypes.GET_USER_BROADCASTS, 'user broadcasts list'),
    createBroadcast: createAsyncAction(actionTypes.CREATE_NEW_BROADCAST, 'create broadcast'),
    deleteBroadcast: createAsyncAction(actionTypes.DELETE_BROADCAST, 'delete broadcast')
};
