import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import {
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon
} from '@mui/icons-material';

import {MEDIA_TYPES} from 'constants';

import {Styles} from './ControlButtons.styles';
import {normalizeStreamMember} from 'services/helpers';

export const ControlButtons = ({
    capture,
    toggleSelfVideo,
    toggleSelfAudio,
    broadcastGuestAuth,
    authLoginSuccess,
    createBroadcast,
    isGuestUser,
    userName
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {broadcastId} = useParams();
    const redirectDataRef = useRef(null);

    useEffect(() => {
        if (location.state) redirectDataRef.current = location.state;
    }, [location]);

    const [micEnabled, setMicEnabled] = useState(true);
    const [camEnabled, setCamEnabled] = useState(true);

    const onToggleMediaDevice = (mediaKind) => () => {
        const enabled = mediaKind === MEDIA_TYPES.audio ? micEnabled : camEnabled;
        const setEnabled = mediaKind === MEDIA_TYPES.audio ? setMicEnabled : setCamEnabled;
        mediaKind === MEDIA_TYPES.audio ? toggleSelfAudio() : toggleSelfVideo();

        capture.getTracks().forEach((track) => {
            if (track.kind === mediaKind) {
                track.enabled = !enabled;
                setEnabled(!enabled);
            }
        });
    };

    const broadcastAuthSuccess = () => {
        isGuestUser &&
            authLoginSuccess({
                profileObj: normalizeStreamMember({
                    user: {
                        displayName: userName
                    }
                })
            });
        createBroadcast(broadcastId);
        navigate(`/broadcast/${broadcastId}`);
    };

    const onEnterBroadcast = () =>
        isGuestUser ? broadcastGuestAuth({displayName: userName}, broadcastAuthSuccess) : broadcastAuthSuccess();

    return (
        <Styles>
            <div className="wrapper">
                <div>
                    <Button
                        variant="contained"
                        startIcon={micEnabled ? <MicIcon /> : <MicOffIcon />}
                        className="button"
                        color="primary"
                        onClick={onToggleMediaDevice(MEDIA_TYPES.audio)}
                        disabled={!capture}
                    >
                        {micEnabled ? 'mute' : 'unmute'}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={camEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
                        className="button"
                        color="primary"
                        onClick={onToggleMediaDevice(MEDIA_TYPES.video)}
                        disabled={!capture}
                    >
                        {`${camEnabled ? 'stop' : 'start'} video`}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={onEnterBroadcast}
                        variant="contained"
                        className="button"
                        color="primary"
                        disabled={!capture || (isGuestUser && !userName)}
                    >
                        enter broadcast
                    </Button>
                </div>
            </div>
        </Styles>
    );
};

ControlButtons.propTypes = {
    capture: PropTypes.object,
    toggleSelfVideo: PropTypes.func,
    toggleSelfAudio: PropTypes.func,
    broadcastGuestAuth: PropTypes.func,
    authLoginSuccess: PropTypes.func,
    createBroadcast: PropTypes.func,
    isGuestUser: PropTypes.bool,
    userName: PropTypes.string
};
