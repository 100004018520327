import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from 'store/rootSaga';
import rootReducer from './rootReducer';
import {env, envList} from 'config';

const sagaMiddleware = createSagaMiddleware();

const middleware = [thunk, sagaMiddleware];

const store =
    env === envList.develop
        ? createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)))
        : createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(rootSaga);

export default store;
