import {styled} from '@mui/system';

export const Styles = styled('div')(({theme}) => ({
    '.micIndicator': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '300px',
        marginLeft: 20
    },
    '.title': {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.0015em',
        margin: '50px 0 0 0'
    },
    '.canvasContainer': {
        width: 96,
        height: 24,
        background: theme.palette.text.secondary,
        borderRadius: '5px',
        marginTop: 50,
        position: 'relative',
        transform: 'rotate(-90deg)'
    },
    '& .canvas': {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 6
    },
    '& .deviceSelect': {
        width: 180,
        '& > .MuiInputBase-root': {
            //TODO: refactor
            height: '100%'
        }
    },
    '& .selectedValue': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        paddingTop: 0,
        paddingBottom: 0
    },
    '& .arrowDownIcon': {
        color: theme.palette.text.primary
    }
}));
