import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
    VIDEO_ROOM_EVENTS,
    MS_EVENTS,
    MEDIA_TYPES,
    STREAM_AUTHENTICATE_RESULT,
    SIGNALING_TRANSPORT_STATES,
    BROADCAST_LAYOUT_STREAM_TYPES
} from 'constants';
import {lobbySelectors, lobbyActions} from 'store/lobby';
import {broadcastSelectors, broadcastActions} from 'store/broadcast';

export const useCreateBroadcast = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const broadcastInstance = useSelector(broadcastSelectors.getBroadcastInstance);
    const storeClient = useSelector(broadcastSelectors.getBroadcastClient);
    const accessToken = useSelector(broadcastSelectors.getBroadcastToken);
    const stream = useSelector(lobbySelectors.getVideoCapture);

    const [loading, setLoading] = useState(false);

    const leaveBroadcast = useCallback(
        (isReconnect) => {
            dispatch(broadcastActions.clearState(isReconnect));
            dispatch(lobbyActions.disableScreenShare());
        },
        [dispatch]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', leaveBroadcast);
        return () => {
            window.removeEventListener('beforeunload', leaveBroadcast);
        };
    }, [leaveBroadcast, storeClient]);

    useEffect(() => {
        return () => {
            leaveBroadcast();
        };
    }, [leaveBroadcast]);

    const createRoom = useCallback(async () => {
        if (!accessToken || !storeClient) return;
        setLoading(true);

        let client = storeClient;

        const audioTrack = stream.getAudioTracks()[0];
        const videoTrack = stream.getVideoTracks()[0];

        let broadcast = broadcastInstance;
        try {
            broadcast.events.on(VIDEO_ROOM_EVENTS.STREAM_JOINED, (stream) => {
                dispatch(broadcastActions.addStream(stream));

                if (
                    stream.type === BROADCAST_LAYOUT_STREAM_TYPES.IngressRTMP ||
                    stream.type === BROADCAST_LAYOUT_STREAM_TYPES.S3
                ) {
                    toast.info(`${stream.type} stream added to the broadcast`);
                } else {
                    toast.info(`${stream.user.displayName} has joined the broadcast`);
                }
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.STREAM_LEFT, ({id: streamId}) =>
                dispatch(broadcastActions.removeStream(streamId))
            );
            broadcast.events.on(VIDEO_ROOM_EVENTS.LAYOUT_CHANGED, ({layout: {streams: layoutStreams}, streams}) => {
                const isSelfStream = !!layoutStreams.find((layoutStream) => layoutStream.id === client.sessionId);
                const updatedLayout = layoutStreams
                    .filter((layoutStream) => layoutStream.id !== client.sessionId)
                    .map((layoutStream) => ({
                        ...layoutStream,
                        consumers: streams.find((stream) => stream.id === layoutStream.id)?.consumers || []
                    }));

                const broadcastStreams = streams
                    .filter((stream) => !updatedLayout.find((layoutStream) => layoutStream.id === stream.id))
                    .reduce((acc, stream) => ({...acc, [stream.id]: stream}), {});

                dispatch(
                    broadcastActions.addRemoteStreamsToLayout({streams: updatedLayout, isSelfStream, broadcastStreams})
                );
            });

            broadcast.events.on(MS_EVENTS.CONSUMER.CREATED, ({appData, consumer}) => {
                dispatch(broadcastActions.addConsumer({consumer, streamId: appData.streamId}));
            });
            broadcast.events.on(MS_EVENTS.CONSUMER.DESTROYED, ({appData, consumerId}) => {
                dispatch(broadcastActions.closeConsumer({streamId: appData.streamId, consumerId}));
            });
            broadcast.events.on(MS_EVENTS.CONSUMER.PAUSED, ({appData, consumerId}) => {
                dispatch(broadcastActions.pauseConsumer({streamId: appData.streamId, consumerId}));
            });
            broadcast.events.on(MS_EVENTS.CONSUMER.RESUMED, ({appData, consumerId}) => {
                dispatch(broadcastActions.resumeConsumer({streamId: appData.streamId, consumerId}));
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.PRODUCER_TOGGLE, (producerType) => {
                switch (producerType) {
                    case MEDIA_TYPES.audio:
                        broadcast.mediaSession?.isMuted
                            ? broadcast.mediaSession?.unmuteMic()
                            : broadcast.mediaSession?.muteMic();

                        dispatch(lobbyActions.toggleSelfAudio());
                        break;
                    case MEDIA_TYPES.video:
                        broadcast.mediaSession?.isVideoEnabled
                            ? broadcast.mediaSession?.disableVideo()
                            : dispatch(lobbyActions.getLocalStream());
                        dispatch(lobbyActions.toggleSelfVideo());
                        break;
                    case MEDIA_TYPES.consumerScreenShare:
                        broadcast.mediaSession?.isScreenShared
                            ? dispatch(lobbyActions.disableScreenShare())
                            : dispatch(lobbyActions.getScreenShareStream());
                        dispatch(lobbyActions.toggleScreenShareVideo());
                        break;
                    default:
                        break;
                }
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.BROADCAST_DESTROYED, () => {
                navigate('/');
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.STREAM_KICKED, () => {
                toast.info('You have been removed from the broadcast');
                navigate('/');
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.RECORDING_STARTED, () => {
                dispatch(broadcastActions.recordingStarted());
            });
            broadcast.events.on(VIDEO_ROOM_EVENTS.RECORDING_STOPPED, () => {
                dispatch(broadcastActions.recordingStopped());
            });

            const broadcastState = await broadcast.join();

            client.events.on(SIGNALING_TRANSPORT_STATES.CONNECTED, async () => {
                try {
                    const response = await client.authenticate();

                    if (response === STREAM_AUTHENTICATE_RESULT.CREATED) {
                        leaveBroadcast(true);
                    } else {
                        await broadcast.restoreState();
                    }
                } catch (error) {
                    dispatch(broadcastActions.createBroadcastFailure());
                }
            });

            await broadcast.mediaSession?.enableMic({track: audioTrack});
            !audioTrack.enabled && broadcast.mediaSession?.muteMic();

            videoTrack.enabled && (await broadcast.mediaSession?.enableVideo({track: videoTrack}));

            dispatch(broadcastActions.joinBroadcast(broadcastState));
            dispatch(broadcastActions.setBroadcastInstance(broadcast));
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        // eslint-disable-next-line
    }, [accessToken, storeClient]);

    useEffect(() => {
        createRoom();
    }, [createRoom]);

    return {loading};
};
