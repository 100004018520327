import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogContent} from '@mui/material';
import ReactPlayer from 'react-player';

import styles from './ViewRecordingDialog.module.scss';

export function ViewRecordingDialog({open, onClose, recordingSrc}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: styles.dialogContainer
            }}
        >
            <DialogContent>
                <ReactPlayer
                    url={recordingSrc}
                    playing
                    className={styles.player}
                    controls
                    width="100%"
                    height="100%"
                    volume={1}
                />
            </DialogContent>
        </Dialog>
    );
}

ViewRecordingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    recordingSrc: PropTypes.string.isRequired
};
