import {connect} from 'react-redux';
import {GuestQueue} from './GuestQueue';
import {broadcastSelectors, broadcastActions} from 'store/broadcast';
import {lobbySelectors} from 'store/lobby';
import {userSelectors} from 'store/user';

export default connect(
    (state) => ({
        videoCapture: lobbySelectors.getVideoCapture(state),
        broadcastData: broadcastSelectors.getBroadcastData(state),
        layout: broadcastSelectors.getBroadcastLayout(state),
        user: userSelectors.getUserData(state),
        isSelfStreamAdded: broadcastSelectors.getBroadcastSelfStreamAdded(state)
    }),
    {
        addStreamToLayout: broadcastActions.addStreamToLayout,
        kickStream: broadcastActions.kickStream
    }
)(GuestQueue);
