import React from 'react';
import PropTypes from 'prop-types';
import {FullscreenExit as MinimizeScreenIcon, Fullscreen as FullscreenIcon} from '@mui/icons-material';

import styles from '../../Broadcast.module.scss';

export function FullScreenButton({fullScreen, setFullScreen}) {
    return (
        <div className={styles.buttonsContainer} onClick={() => setFullScreen(!fullScreen)}>
            <button className={styles.toggleFullscreen}>
                {fullScreen && (
                    <div>
                        <MinimizeScreenIcon />
                        <span>Minimize screen</span>
                    </div>
                )}
                {!fullScreen && (
                    <div>
                        <FullscreenIcon />
                        <span>Full screen</span>
                    </div>
                )}
            </button>
        </div>
    );
}

FullScreenButton.propTypes = {
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func
};
