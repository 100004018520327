import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';

import {Styles} from './GuestUserName.styles';

export function GuestUserName({value, onChange}) {
    return (
        <Styles>
            <TextField
                value={value}
                variant="outlined"
                type="text"
                label="User name"
                margin="normal"
                className="user-name-input"
                onChange={onChange}
            />
        </Styles>
    );
}

GuestUserName.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};
