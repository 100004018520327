import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';

export function ListComponent({recordings, onOpenRecordingView}) {
    return (
        <List>
            {recordings.map((recording) => (
                <ListItem key={recording.id}>
                    <ListItemText primary={recording.title} />
                    <Button onClick={() => onOpenRecordingView(recording.src)} variant="contained">
                        View
                    </Button>
                </ListItem>
            ))}
        </List>
    );
}

ListComponent.propTypes = {
    recordings: PropTypes.array.isRequired,
    onOpenRecordingView: PropTypes.func.isRequired
};
