import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hark from 'hark';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import VideoAvatar from '../Avatar';
import MoreMenu from '../VideoRoom/components/Stream/components/MoreMenu';

import {handleCanPlay} from 'services/helpers';

import styles from './SelfVideo.module.scss';

export const SelfVideo = ({
    videoCapture,
    user,
    streams,
    isCameraOff,
    shareScreenCapture,
    isScreenShared,
    client,
    isHost,
    isGuestQueue,
    onAddStreamToLayout
}) => {
    const selfVideoRef = useRef();
    const screenVideoRef = useRef();
    const [isActiveSpeaker, setIsActiveSpeaker] = useState(false);
    const [isHiddenVideo, setIsHiddenVideo] = useState(false);
    const activeStreamsCount = streams.length;

    useEffect(() => {
        if (selfVideoRef?.current) selfVideoRef.current.srcObject = videoCapture;

        if (screenVideoRef?.current && shareScreenCapture) screenVideoRef.current.srcObject = shareScreenCapture;
    }, [isScreenShared, shareScreenCapture, videoCapture]);

    useEffect(() => {
        if (videoCapture) {
            const speechEvents = hark(videoCapture, {});

            speechEvents.on('speaking', () => {
                if (activeStreamsCount) setIsActiveSpeaker(true);
            });

            speechEvents.on('stopped_speaking', () => setIsActiveSpeaker(false));
        }
    }, [activeStreamsCount, videoCapture]);

    const toggleSeltVideoPlate = () => setIsHiddenVideo(!isHiddenVideo);

    return (
        <div
            className={classNames(styles.videoPlate, {
                [styles.activeStreamsVideoPlate]: activeStreamsCount && !isGuestQueue,
                [styles.isActiveSpeaker]: isActiveSpeaker && !isGuestQueue,
                [styles.isHiddenVideo]: isHiddenVideo && !isGuestQueue,
                [styles.isGuestQueue]: isGuestQueue
            })}
        >
            {!!activeStreamsCount && !isGuestQueue && (
                <Button onClick={toggleSeltVideoPlate} className={styles.toggleSelfVideoButton}>
                    {isHiddenVideo ? (
                        <VisibilityIcon className={styles.visibilityIcon} />
                    ) : (
                        <VisibilityOffIcon className={styles.visibilityIcon} />
                    )}
                </Button>
            )}
            {isCameraOff && !isScreenShared && (
                <div className={styles.userName}>
                    <VideoAvatar
                        member={user?.profileObj}
                        isMinified={isGuestQueue || !!activeStreamsCount}
                        isSelfAvatar
                    />
                </div>
            )}
            <video
                className={classNames(styles.video, {[styles.hidden]: isCameraOff || isScreenShared})}
                ref={selfVideoRef}
                muted
                autoPlay
                playsInline
                onCanPlay={() => handleCanPlay(selfVideoRef)}
            />
            <video
                className={classNames(styles.video, {[styles.hidden]: !isScreenShared})}
                ref={screenVideoRef}
                muted
                autoPlay
                playsInline
                onCanPlay={() => handleCanPlay(screenVideoRef)}
            />
            {isGuestQueue && (
                <div className={styles.buttonWrapper}>
                    <Button
                        onClick={onAddStreamToLayout({
                            id: client.sessionId,
                            isSelfStream: true
                        })}
                        className={styles.addButton}
                    >
                        <AddCircleIcon className={styles.addIcon} />
                    </Button>
                </div>
            )}
            {!isGuestQueue && isHost && <MoreMenu isSelfStream />}
        </div>
    );
};

SelfVideo.propTypes = {
    videoCapture: PropTypes.object.isRequired,
    isCameraOff: PropTypes.bool.isRequired,
    shareScreenCapture: PropTypes.object,
    isScreenShared: PropTypes.bool,
    client: PropTypes.object,
    user: PropTypes.object,
    streams: PropTypes.arrayOf(PropTypes.object),
    isGuestQueue: PropTypes.bool,
    isHost: PropTypes.bool,
    onAddStreamToLayout: PropTypes.func
};
