export const typography = {
    h1: {
        fontSize: '4.8rem',
        fontWeight: 700,
        lineHeight: '6.557rem'
    },
    h2: {
        fontSize: '4rem',
        fontWeight: 600,
        lineHeight: '5.7rem',
        letterSpacing: '2px'
    },
    h3: {
        fontSize: '3.2rem',
        fontWeight: 600,
        lineHeight: '4.371rem'
    },
    h4: {
        fontSize: '2.4rem',
        fontWeight: 600,
        lineHeight: '2.926rem'
    },
    h5: {
        fontSize: '1.8rem',
        fontWeight: 700,
        lineHeight: '2.459rem'
    },
    h6: {
        fontSize: '2.2rem',
        fontWeight: 600,
        lineHeight: '2.8rem'
    }
};
