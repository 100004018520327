import React, {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Mic as MicIcon} from '@mui/icons-material';

import {lobbySelectors} from 'store/lobby';
import {createAudioMeter} from 'services/helpers';

import {Styles} from './AudioStream.styles';

const CANVAS_WIDTH = 96;
const CANVAS_HEIGHT = 24;

export function AudioStream() {
    const canvasRef = useRef();
    const capture = useSelector(lobbySelectors.getVideoCapture);

    useEffect(() => {
        if (capture) {
            const canvas = canvasRef.current;
            // eslint-disable-next-line no-undef
            const audioContext = new AudioContext();
            const mediaStreamSource = audioContext.createMediaStreamSource(capture);

            // Create a new volume meter and connect it.
            const meter = createAudioMeter(audioContext);
            mediaStreamSource.connect(meter);

            const canvasContext = canvas.getContext('2d');

            drawLoop(canvasContext, meter);

            // eslint-disable-next-line no-inner-declarations
            function drawLoop() {
                // clear the background
                canvasContext.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

                canvasContext.fillStyle = '#56bf53'; // TODO: change to theme

                // draw a bar based on the current volume
                canvasContext.fillRect(0, 0, meter.volume * CANVAS_WIDTH * 1.4, CANVAS_HEIGHT);

                // set up the next visual callback
                // eslint-disable-next-line no-undef
                window.requestAnimationFrame(drawLoop);
            }
        }
        // eslint-disable-next-line
    }, [capture]);

    return (
        <Styles>
            <div className="micIndicator">
                <MicIcon />
                <div className="canvasContainer">
                    <canvas ref={canvasRef} className="canvas" width="96" height="24" />
                </div>
                <p className="title">Microphone</p>
            </div>
        </Styles>
    );
}
