import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';

import {userSelectors, userActions} from 'store/user';
import {broadcastActions} from 'store/broadcast';
import {googleAuthClientId} from 'config';
import {refreshAuthToken} from 'services/helpers';

export function Auth() {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.getUserData);
    // eslint-disable-next-line no-undef
    const isSignedIn = localStorage.getItem('accessToken');

    const onLoginSuccess = useCallback((res) => {
        const payload = {
            accessToken: res.accessToken,
            profileObj: res.profileObj,
            tokenObj: res.tokenObj
        };

        dispatch(broadcastActions.broadcastAuth(res.tokenObj));
        dispatch(userActions.authLoginSuccess(payload));

        refreshAuthToken(res);
    }, []);

    const onLoginFailure = () => {};

    const onLogoutSuccess = useCallback(() => dispatch(userActions.authLogoutSuccess()), []);

    return (
        <>
            {!user ? (
                <GoogleLogin
                    clientId={googleAuthClientId}
                    render={(renderProps) => (
                        <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            variant="contained"
                            startIcon={<GoogleIcon />}
                        >
                            Login
                        </Button>
                    )}
                    buttonText="Login"
                    onSuccess={onLoginSuccess}
                    onFailure={onLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={isSignedIn}
                />
            ) : (
                <GoogleLogout
                    clientId={googleAuthClientId}
                    render={(renderProps) => (
                        <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            variant="contained"
                            startIcon={<GoogleIcon />}
                        >
                            Logout
                        </Button>
                    )}
                    buttonText="Logout"
                    onLogoutSuccess={onLogoutSuccess}
                />
            )}
        </>
    );
}
