import React from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import styles from './ShareBroadcastButton.module.scss';

export function ShareBroadcastButton({toggleSuccessSnackbar}) {
    const onCopyToClipboard = () => toggleSuccessSnackbar(true);
    const {broadcastId} = useParams();

    return (
        <div className={styles.shareButtonWrapper}>
            <CopyToClipboard text={`${window.location.origin}/lobby/${broadcastId}`} onCopy={onCopyToClipboard}>
                <Button onClick={onCopyToClipboard} variant="contained" startIcon={<ContentCopyIcon />}>
                    Share broadcast link
                </Button>
            </CopyToClipboard>
        </div>
    );
}

ShareBroadcastButton.propTypes = {
    toggleSuccessSnackbar: PropTypes.func.isRequired
};
