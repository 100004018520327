import {actionTypes} from './actions';

const initialState = {
    data: null,
    loading: false
};

const userReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.LOG_IN.SUCCESS: {
            return {
                ...state,
                data: payload,
                loading: false
            };
        }
        case actionTypes.LOG_OUT.SUCCESS: {
            return initialState;
        }
        default:
            return state;
    }
};

export default userReducer;
