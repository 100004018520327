import {connect} from 'react-redux';
import {Broadcast} from './Broadcast';
import {lobbySelectors} from 'store/lobby';
import {userSelectors} from 'store/user';
import {broadcastSelectors} from 'store/broadcast';

export default connect((state) => ({
    videoCapture: lobbySelectors.getVideoCapture(state),
    client: broadcastSelectors.getBroadcastClient(state),
    broadcastData: broadcastSelectors.getBroadcastData(state),
    broadcastLayout: broadcastSelectors.getBroadcastLayout(state),
    user: userSelectors.getUserData(state),
    isHost: broadcastSelectors.getBroadcastHostStatus(state),
    isSelfStreamAdded: broadcastSelectors.getBroadcastSelfStreamAdded(state)
}))(Broadcast);
