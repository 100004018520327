import {styled} from '@mui/system';

export const Styles = styled('div')(({theme}) => ({
    '&. wrapper': {
        background: theme.palette.grey[0],
        border: `1px solid ${theme.palette.primary.main}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginTop: 15,
        padding: 8
    },
    '& .text': {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.darkGray,
        margin: 0,
        textAlign: 'center'
    }
}));
