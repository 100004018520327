import {call} from 'redux-saga/effects';
import Api from 'api';
import {handleAsyncActionSaga} from 'services/redux';
import {actionTypes} from './actions';

function* broadcastAuth(payload) {
    return yield call(Api.request, {
        url: '/auth/login',
        method: 'post',
        payload
    });
}

function* broadcastGuestAuth(payload) {
    return yield call(Api.request, {
        url: '/auth/guest-token',
        method: 'post',
        payload
    });
}

export default function* broadcastSaga() {
    yield handleAsyncActionSaga(actionTypes.BROADCAST_AUTH, broadcastAuth);
    yield handleAsyncActionSaga(actionTypes.BROADCAST_GUEST_AUTH, broadcastGuestAuth);
}
