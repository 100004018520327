import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import styles from './NavTabs.module.scss';

const NAV_ROUTES = {
    '/': 0,
    '/recordings': 1
};

export function NavTabs() {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (activeTab !== NAV_ROUTES[pathname]) setActiveTab(NAV_ROUTES[pathname]);
    }, []);

    const handleChange = (event, newValue) => {
        const path = Object.keys(NAV_ROUTES)[newValue];
        setActiveTab(newValue);
        navigate(path);
    };

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                classes={{
                    root: styles.tabsContainer,
                    indicator: styles.tabsIndicator
                }}
            >
                <Tab
                    label="Live broadcasts"
                    classes={{
                        selected: styles.selectedTab
                    }}
                />
                <Tab
                    label="Recorded broadcasts"
                    classes={{
                        selected: styles.selectedTab
                    }}
                />
            </Tabs>
        </Box>
    );
}
