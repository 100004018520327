import {createAsyncActionTypes, createActionType} from 'services/redux';
import {MEDIA_DEVICES_KINDS, MEDIA_TYPES} from 'constants/lobby';

const SERVICE_NAME = 'LOBBY';

export const actionTypes = {
    GET_USER_MEDIA: createAsyncActionTypes(SERVICE_NAME, 'Get user media'),
    GET_SCREEN_MEDIA: createAsyncActionTypes(SERVICE_NAME, 'Get screen media'),
    SET_MEDIA_DEVICES_LIST: createActionType(SERVICE_NAME, 'Set media devices list'),
    TOGGLE_SELF_VIDEO: createActionType(SERVICE_NAME, 'toggle self video'),
    TOGGLE_SELF_AUDIO: createActionType(SERVICE_NAME, 'toggle self audio'),
    TOGGLE_SCREEN_SHARE: createActionType(SERVICE_NAME, 'toggle screen share')
};

const getLocalStreamRequest = () => ({type: actionTypes.GET_USER_MEDIA.REQUEST});
const getLocalStreamSuccess = (payload) => ({type: actionTypes.GET_USER_MEDIA.SUCCESS, payload});
const getLocalStreamFailure = () => ({type: actionTypes.GET_USER_MEDIA.FAILURE});

const setMediaDevicesList = (payload) => ({type: actionTypes.SET_MEDIA_DEVICES_LIST, payload});

const toggleSelfVideo = (payload) => ({type: actionTypes.TOGGLE_SELF_VIDEO, payload});

const toggleSelfAudio = (payload) => ({type: actionTypes.TOGGLE_SELF_AUDIO, payload});

const getLocalStream = (constrains, isReconnect) => async (dispatch, getState) => {
    const {
        lobby: {constrains: initialConstrains, isAudioPaused, isVideoPaused},
        broadcast: {broadcastData}
    } = getState();

    try {
        dispatch(getLocalStreamRequest());

        console.info('Getting access to user media devices');

        const mediaDevices = await navigator.mediaDevices.enumerateDevices();

        const mediaInputSources = mediaDevices.filter(
            (device) => device.kind === MEDIA_DEVICES_KINDS.audioinput || device.kind === MEDIA_DEVICES_KINDS.videoinput
        );

        const getConstraints = {...initialConstrains, ...constrains};

        dispatch(setMediaDevicesList({mediaInputSources, constrains: getConstraints}));

        const localStream = await navigator.mediaDevices.getUserMedia(getConstraints);

        if (isReconnect) {
            localStream.getTracks().forEach((track) => {
                if (track.kind === MEDIA_TYPES.audio) {
                    track.enabled = !isAudioPaused;
                } else {
                    track.enabled = !isVideoPaused;
                }
            });
        }

        dispatch(getLocalStreamSuccess(localStream));

        // isReconnect && dispatch(createBroadcast(broadcastSlug));

        if (broadcastData?.mediaSession) {
            const newVideoTrack = localStream.getVideoTracks()[0];

            await broadcastData?.mediaSession.enableVideo({track: newVideoTrack});
        }
    } catch (err) {
        console.error('Error opening video camera.', err);
        dispatch(getLocalStreamFailure());
    }
};

const toggleScreenShareVideo = (payload) => ({type: actionTypes.TOGGLE_SCREEN_SHARE, payload});

const getScreenShareStream = () => async (dispatch, getState) => {
    const {
        broadcast: {broadcastData}
    } = getState();

    try {
        dispatch(getScreenShareStreamRequest());

        console.info('Getting access to screen media');

        const screenShareStream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
                displaySurface: 'monitor',
                logicalSurface: true,
                cursor: true,
                width: {max: 1920},
                height: {max: 1080},
                frameRate: {max: 30}
            }
        });

        screenShareStream.oninactive = () => dispatch(toggleScreenShareVideo(false));

        dispatch(getScreenShareStreamSuccess(screenShareStream));

        const newVideoTrack = screenShareStream.getVideoTracks()[0];

        await broadcastData?.mediaSession.enableShare({track: newVideoTrack});
    } catch (err) {
        console.error('Error getting screen capture.', err);
        dispatch(toggleScreenShareVideo(false));
        dispatch(getScreenShareStreamFailure());
    }
};

const getScreenShareStreamRequest = () => ({type: actionTypes.GET_SCREEN_MEDIA.REQUEST});
const getScreenShareStreamSuccess = (payload) => ({type: actionTypes.GET_SCREEN_MEDIA.SUCCESS, payload});
const getScreenShareStreamFailure = () => ({type: actionTypes.GET_SCREEN_MEDIA.FAILURE});

const disableScreenShare = () => async (dispatch, getState) => {
    const {
        lobby: {shareScreenCapture},
        broadcast: {broadcastData}
    } = getState();

    try {
        const videoTrack = shareScreenCapture.getVideoTracks()[0];

        await broadcastData?.mediaSession.disableShare({track: videoTrack});

        shareScreenCapture.getVideoTracks().forEach((track) => track.stop());
    } catch (error) {
        console.warn(error);
    }
};

export const actions = {
    toggleSelfVideo,
    toggleSelfAudio,
    getLocalStream,
    getScreenShareStream,
    toggleScreenShareVideo,
    disableScreenShare
};
