import {createActionType, createAction} from 'services/redux';

const SERVICE_NAME = 'LOADER';

export const actionTypes = {
    LOADER_ON: createActionType(SERVICE_NAME, 'Set on'),
    LOADER_OFF: createActionType(SERVICE_NAME, 'Set off')
};

export const setLoaderOn = createAction(actionTypes.LOADER_ON);

export const setLoaderOff = createAction(actionTypes.LOADER_OFF);
