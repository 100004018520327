import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiAvatar from '@mui/material/Avatar';
import {deepOrange} from '@mui/material/colors';

import styles from './Avatar.module.scss';

export const Avatar = ({member, isMinified, isSelfAvatar}) => {
    const avatarSrc = useMemo(() => {
        let src;
        if (member) {
            if (isSelfAvatar && member.imageUrl) {
                src = member.imageUrl;
            } else {
                src = member.avatarUrl;
            }
        }
        return src;
    }, [member]);

    const initials = useMemo(() => {
        return member
            ? `${member.givenName && !!member.givenName.length ? member.givenName[0] : ''}${
                  member.familyName && !!member.familyName.length ? member.familyName[0] : ''
              }`.toUpperCase()
            : '';
    }, [member]);

    return (
        <div className={classNames(styles.avatar, {[styles.minified]: isMinified})}>
            {!!member && (
                <MuiAvatar
                    alt={initials}
                    src={avatarSrc}
                    className={classNames(styles.image, {[styles.imageSmall]: isMinified})}
                    sx={{bgcolor: deepOrange[500]}}
                >
                    {initials}
                </MuiAvatar>
            )}
        </div>
    );
};

Avatar.propTypes = {
    member: PropTypes.object,
    isMinified: PropTypes.bool,
    isSelfAvatar: PropTypes.bool
};
