import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ListWrapper from 'components/ListWrapper';
import List from './components/List';
import ViewRecordingDialog from './components/ViewRecordingDialog';

import {getBucketList} from 'services/helpers';

import styles from './RecordingsList.module.scss';

export function RecordingsList() {
    const [recordings, setRecordings] = useState([]);
    const [viewRecordingDialog, toggleViewRecordingDialog] = useState(false);
    const [currentRecordingView, setCurrentRecordingView] = useState(null);

    const getRecordingsList = async () => {
        const list = await getBucketList();

        setRecordings(list);
    };

    useEffect(() => {
        getRecordingsList();
    }, []);

    const onOpenRecordingView = (src) => {
        setCurrentRecordingView(src);
        toggleViewRecordingDialog(true);
    };

    const onCloseModal = () => {
        setCurrentRecordingView(null);
        toggleViewRecordingDialog(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}></div>
            <ListWrapper>
                <List recordings={recordings} onOpenRecordingView={onOpenRecordingView} />
            </ListWrapper>
            {viewRecordingDialog && !!currentRecordingView && (
                <ViewRecordingDialog
                    open={viewRecordingDialog && !!currentRecordingView}
                    onClose={onCloseModal}
                    recordingSrc={currentRecordingView}
                />
            )}
        </div>
    );
}

RecordingsList.propTypes = {
    user: PropTypes.object
};
