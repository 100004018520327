import {createSelector} from 'reselect';

const lobby = (state) => state.lobby;

export const getRoomSessions = createSelector(lobby, (state) => state.sessions);

export const getRoomClient = createSelector(lobby, (state) => state.client);

export const getVideoCapture = createSelector(lobby, (state) => state.capture);

export const getVideoRoom = createSelector(lobby, (state) => state.room);

export const getMediaCaptureError = createSelector(lobby, (state) => state.isMediaCaptureError);

export const getScreenShareCapture = createSelector(lobby, (state) => state.shareScreenCapture);

export const getMediaInputSources = createSelector(lobby, (state) => state.mediaInputSources);

export const getIsCameraOff = createSelector(lobby, (state) => state.isVideoPaused);

export const getIsAudioOff = createSelector(lobby, (state) => state.isAudioPaused);

export const getInitialConstrains = createSelector(lobby, (state) => state.constrains);

export const getIsScreenShared = createSelector(lobby, (state) => state.isScreenShared);

export const getConsumers = createSelector(lobby, (state) => state.consumers);
