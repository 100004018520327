export const palette = {
    primary: {
        main: '#3E2ABE',
        light: 'rgba(116, 116, 128, 0.18)'
    },
    secondary: {
        main: '#385AF7',
        light: '#5A6684'
    },
    info: {
        main: 'rgba(0, 220, 255, 1)'
    },
    background: {
        main: '#D6DDEA',
        paper: '#262626',
        indicatorOn: '#56bf53'
    },
    divider: '#333333',
    action: {
        active: '#0A84FF',
        disabledBackground: 'rgb(0, 0, 0, 15%)',
        icon: 'rgba(255, 255, 255, 0.6)'
    },
    text: {
        primary: '#000000',
        secondary: '#FFFFFF',
        disabled: 'rgba(0, 0, 0, 0.3)'
    },
    error: {
        main: '#FF453A'
    },
    status: {
        danger: '#e53e3e'
    }
};
