import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import GuestStream from './components/GuestStream';
import SelfVideo from '../SelfVideo';

import {PARTICIPANT_TYPES} from 'constants';

import styles from './GuestQueue.module.scss';

export function GuestQueue({
    videoCapture,
    broadcastData: {streams: guestStreams},
    layout,
    user,
    addStreamToLayout,
    kickStream,
    isSelfStreamAdded
}) {
    const onAddStreamToLayout = useCallback(
        (stream) => () =>
            addStreamToLayout({
                streamId: stream.id,
                isSelfStream: stream.isSelfStream
            }),
        [addStreamToLayout]
    );
    const onRemoveStream = useCallback(
        (stream) => () =>
            kickStream({
                streamId: stream.id,
                type: stream.type
            }),
        [kickStream]
    );

    return (
        <div className={styles.container}>
            <p className={styles.title}>Broadcast streams queue</p>
            <div className={styles.inner}>
                {!isSelfStreamAdded && (
                    <SelfVideo
                        videoCapture={videoCapture}
                        user={user}
                        streams={layout?.streams}
                        isGuestQueue
                        onAddStreamToLayout={onAddStreamToLayout}
                    />
                )}
                {!!Object.values(guestStreams)?.length &&
                    Object.values(guestStreams)
                        .filter((guestStream) => guestStream?.user?.role !== PARTICIPANT_TYPES.mixer)
                        .map((stream) => (
                            <GuestStream
                                key={stream?.id}
                                stream={stream}
                                onAddStreamToLayout={onAddStreamToLayout}
                                onRemoveStream={onRemoveStream}
                            />
                        ))}
            </div>
        </div>
    );
}

GuestQueue.propTypes = {
    videoCapture: PropTypes.object,
    user: PropTypes.object,
    broadcastData: PropTypes.object,
    layout: PropTypes.object,
    addStreamToLayout: PropTypes.func,
    kickStream: PropTypes.func,
    isSelfStreamAdded: PropTypes.bool
};
