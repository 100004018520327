import {palette} from './palette';

export const overrides = {
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                height: '100%',
                minHeight: '100%'
            },
            body: {
                minHeight: '100%',
                backgroundColor: palette.background.main
            }
        }
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                backgroundColor: '#fff'
            }
        }
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                color: '#B4BAC5',
                fontWeight: '600'
            }
        }
    },
    MuiButton: {
        variants: [
            {
                props: {variant: 'secondary'},
                style: {
                    border: 'none',
                    background: '#F1F3F7',
                    borderRadius: '12px',
                    color: '#B4BAC5',
                    fontSize: '1rem',
                    lineHeight: '1.2rem',
                    fontWeight: '600',
                    boxShadow: 'none',
                    padding: '10px 27.5px'
                }
            },
            {
                props: {variant: 'contained'},
                style: {
                    border: 'none',
                    background: '#B4BAC5',
                    borderRadius: '12px',
                    color: '#fff',
                    fontSize: '1rem',
                    lineHeight: '1',
                    fontWeight: '600',
                    boxShadow: 'none',
                    padding: '10px 27.5px',

                    ':hover': {
                        border: 'none',
                        background: palette.background.main,
                        boxShadow: 'none',
                        color: '#B4BAC5'
                    }
                }
            },
            {
                props: {variant: 'outlined'},
                style: {
                    border: '1px solid #B4BAC5',
                    background: '#fff',
                    borderRadius: '12px',
                    color: '#B4BAC5',
                    fontSize: '1rem',
                    lineHeight: '1.2rem',
                    fontWeight: '600',
                    boxShadow: 'none',
                    padding: '10px 27.5px',

                    ':hover': {
                        border: '1px solid #B4BAC5',
                        background: '#fff',
                        boxShadow: 'none'
                    }
                }
            },
            {
                props: {variant: 'text'},
                style: {
                    border: 'none',
                    background: 'none',
                    color: '#B4BAC5',
                    fontSize: '1rem',
                    lineHeight: '1.4rem',
                    fontWeight: '600',
                    heigh: 'auto',
                    boxShadow: 'none',
                    padding: '10px 27.5px',

                    '&:hover': {
                        border: 'none',
                        background: 'none',
                        boxShadow: 'none'
                    }
                }
            }
        ]
    }
};
