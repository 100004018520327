import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Button} from '@mui/material';

import ListWrapper from 'components/ListWrapper';
import TabPanel from './components/TabPanel';
import List from './components/List';
import CreateBroadcastDialog from './components/CreateBroadcastDialog';

import styles from './BroadcastList.module.scss';

export function BroadcastsList({
    allBroadcasts,
    userBroadcasts,
    user,
    getAllBroadcasts,
    getUsersBroadcasts,
    createBroadcast,
    deleteBroadcast
}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [createBroadcastDialog, toggleCreateBroadcast] = useState(false);

    useEffect(() => {
        selectedTab ? getUsersBroadcasts() : getAllBroadcasts();
    }, [selectedTab]);

    const onChangeTab = (e, newValue) => setSelectedTab(newValue);

    const createBroadcastSuccess = () => {
        toggleCreateBroadcast(false);
        getUsersBroadcasts();
    };

    const onCreateBroadcast = useCallback((slug) => createBroadcast({slug}, createBroadcastSuccess), [createBroadcast]);

    const onDeleteBroadcast = useCallback(
        (broadcastId) => deleteBroadcast(broadcastId, getUsersBroadcasts),
        [deleteBroadcast]
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Tabs
                    value={selectedTab}
                    classes={{
                        root: styles.tabsContainer,
                        indicator: styles.tabIndicator
                    }}
                    onChange={onChangeTab}
                >
                    <Tab
                        label="All broadcasts"
                        classes={{
                            root: styles.customTab,
                            selected: styles.selectedTab
                        }}
                    />
                    {user && (
                        <Tab
                            label="User broadcasts"
                            classes={{
                                root: styles.customTab,
                                selected: styles.selectedTab
                            }}
                        />
                    )}
                </Tabs>
                {user && !!selectedTab && (
                    <Button
                        onClick={() => toggleCreateBroadcast(true)}
                        variant="contained"
                        className={styles.createBroadcastsButton}
                    >
                        Create broadcast
                    </Button>
                )}
            </div>
            <ListWrapper>
                <TabPanel value={selectedTab} index={0}>
                    <List broadcasts={allBroadcasts} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <List broadcasts={userBroadcasts} isUsersList onDeleteBroadcast={onDeleteBroadcast} />
                </TabPanel>
            </ListWrapper>
            <CreateBroadcastDialog
                open={createBroadcastDialog}
                onClose={() => toggleCreateBroadcast(false)}
                onSubmit={onCreateBroadcast}
            />
        </div>
    );
}

BroadcastsList.propTypes = {
    allBroadcasts: PropTypes.array.isRequired,
    userBroadcasts: PropTypes.array.isRequired,
    user: PropTypes.object,
    getAllBroadcasts: PropTypes.func.isRequired,
    getUsersBroadcasts: PropTypes.func.isRequired,
    createBroadcast: PropTypes.func.isRequired,
    deleteBroadcast: PropTypes.func.isRequired
};
