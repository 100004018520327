import {toast} from 'react-toastify';
import {actionTypes} from './actions';
import {PARTICIPANT_TYPES, BROADCAST_LAYOUT_STREAM_TYPES} from 'constants';

const initialState = {
    loading: false,
    client: null,
    broadcastData: null,
    broadcastInstance: null,
    authToken: null,
    isSelfStreamAdded: false,
    isRecording: false
};

const broadcastReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.BROADCAST_AUTH.SUCCESS:
        case actionTypes.BROADCAST_GUEST_AUTH.SUCCESS: {
            localStorage.setItem('accessToken', payload.authToken);
            return {
                ...state,
                authToken: payload.authToken
            };
        }
        case actionTypes.CREATE_BROADCAST.SUCCESS: {
            const {client, broadcastData} = payload;
            return {
                ...state,
                client,
                broadcastData,
                broadcastInstance: broadcastData,
                loading: false
            };
        }
        case actionTypes.ADD_STREAM: {
            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: {
                        ...state.broadcastData.streams,
                        [payload.id]: payload
                    }
                }
            };
        }
        case actionTypes.REMOVE_STREAM: {
            const streams = Object.assign({}, state.broadcastData.streams);
            const userName =
                streams[payload]?.user?.displayName ||
                state.broadcastData.layout.streams.find((stream) => stream.id === payload)?.user?.displayName;
            const getStream =
                streams[payload] || state.broadcastData.layout.streams.find((stream) => stream.id === payload);
            const isCloudStreamType =
                getStream?.type === BROADCAST_LAYOUT_STREAM_TYPES.IngressRTMP ||
                getStream?.type === BROADCAST_LAYOUT_STREAM_TYPES.S3;

            if (state.broadcastData.mediaSession.appData.role !== PARTICIPANT_TYPES.mixer && !isCloudStreamType) {
                toast.info(`${userName} has left the broadcast`);
            } else if (state.broadcastData.mediaSession.appData.role === PARTICIPANT_TYPES.mixer) {
                toast.error('Mixer server error');
            } else if (state.broadcastData.mediaSession.appData.role !== PARTICIPANT_TYPES.mixer && isCloudStreamType) {
                toast.info(`${getStream.type} stream has been removed from broadcast`);
            }

            if (streams[payload]) {
                delete streams[payload];
            }

            const updatedLayoutStreams = state.broadcastData.layout.streams.filter((stream) => stream.id !== payload);

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.ADD_CONSUMER: {
            const {consumer, streamId} = payload;
            const isAddedToLayoutStream = !!state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const getStream =
                state.broadcastData.streams[streamId] ||
                state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const updatedConsumers = [...getStream.consumers, consumer];
            const updatedStreams = isAddedToLayoutStream
                ? state.broadcastData.streams
                : {
                      ...state.broadcastData.streams,
                      [streamId]: {
                          ...state.broadcastData.streams[streamId],
                          consumers: updatedConsumers
                      }
                  };
            const updatedLayoutStreams = state.broadcastData.layout.streams.map((stream) => {
                if (stream.id === streamId) {
                    return {
                        ...stream,
                        consumers: updatedConsumers
                    };
                }

                return stream;
            });

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: updatedStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.CLOSE_CONSUMER: {
            const {streamId, consumerId} = payload;
            const isAddedToLayoutStream = !!state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const getStream =
                state.broadcastData.streams[streamId] ||
                state.broadcastData.layout.streams.find((stream) => stream.id === streamId);

            if (!getStream) {
                return state;
            }

            const updatedStream = {
                ...getStream,
                consumers: getStream?.consumers?.filter((consumer) => consumer.id !== consumerId)
            };

            const updatedStreams = isAddedToLayoutStream
                ? state.broadcastData.streams
                : {
                      ...state.broadcastData.streams,
                      [streamId]: updatedStream
                  };

            const updatedLayoutStreams = state.broadcastData.layout.streams.map((stream) => {
                if (stream.id === streamId) {
                    return {
                        ...stream,
                        consumers: stream?.consumers?.filter((consumer) => consumer.id !== consumerId)
                    };
                }

                return stream;
            });

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: updatedStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.PAUSE_CONSUMER: {
            const {streamId, consumerId} = payload;
            const isAddedToLayoutStream = !!state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const getStream =
                state.broadcastData.streams[streamId] ||
                state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const pausedConsumers = {
                ...getStream.pausedConsumers,
                [consumerId]: consumerId
            };
            const updatedStream = {...getStream, pausedConsumers};

            const updatedStreams = isAddedToLayoutStream
                ? state.broadcastData.streams
                : {
                      ...state.broadcastData.streams,
                      [streamId]: updatedStream
                  };

            const updatedLayoutStreams = state.broadcastData.layout.streams.map((stream) => {
                if (stream.id === streamId) {
                    return {
                        ...stream,
                        pausedConsumers
                    };
                }

                return stream;
            });

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: updatedStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.RESUME_CONSUMER: {
            const {streamId, consumerId} = payload;
            const isAddedToLayoutStream = !!state.broadcastData.layout.streams.find((stream) => stream.id === streamId);
            const getStream =
                state.broadcastData.streams[streamId] ||
                state.broadcastData.layout.streams.find((stream) => stream.id === streamId);

            if (!getStream) {
                return state;
            }

            const pausedConsumers = {...getStream.pausedConsumers};

            delete pausedConsumers[consumerId];

            const updatedStream = {...getStream, pausedConsumers};

            const updatedStreams = isAddedToLayoutStream
                ? state.broadcastData.streams
                : {
                      ...state.broadcastData.streams,
                      [streamId]: updatedStream
                  };

            const updatedLayoutStreams = state.broadcastData.layout.streams.map((stream) => {
                if (stream.id === streamId) {
                    return {
                        ...stream,
                        pausedConsumers
                    };
                }

                return stream;
            });

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: updatedStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.JOIN_BROADCAST: {
            const {
                layout: {streams: layoutStreams},
                streams
            } = payload;

            const updatedLayoutStreams = layoutStreams.map((layoutStream) => ({
                ...layoutStream,
                consumers: streams.find((stream) => stream.id === layoutStream.id)?.consumers || []
            }));

            const broadcastStreams = streams
                .filter((stream) => !updatedLayoutStreams.find((layoutStream) => layoutStream.id === stream.id))
                .reduce((acc, stream) => ({...acc, [stream.id]: stream}), {});

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: broadcastStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: updatedLayoutStreams
                    }
                }
            };
        }
        case actionTypes.SET_BROADCAST_INSTANCE: {
            return {
                ...state,
                broadcastInstance: payload
            };
        }
        case actionTypes.ADD_STREAM_TO_LAYOUT: {
            const {streamId, isSelfStream} = payload;
            const streams = Object.assign({}, state.broadcastData.streams);
            const layoutStreams = state.broadcastData.layout.streams;
            const getStream = streams[streamId];

            delete streams[streamId];

            const updatedStreams = [...layoutStreams, getStream];

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: isSelfStream ? state.broadcastData.layout.streams : updatedStreams
                    }
                },
                isSelfStreamAdded: isSelfStream ? isSelfStream : state.isSelfStreamAdded
            };
        }
        case actionTypes.ROMOVE_STREAM_FROM_LAYOUT: {
            const {streamId, isSelfStream} = payload;
            const streams = Object.assign({}, state.broadcastData.streams);
            const layoutStreams = state.broadcastData.layout.streams;
            const getStream = layoutStreams.find((stream) => stream.id === streamId);

            if (!isSelfStream) {
                streams[streamId] = getStream;
            }

            const updatedStreams = layoutStreams.filter((stream) => stream.id !== streamId);

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams: isSelfStream ? state.broadcastData.layout.streams : updatedStreams
                    }
                },
                isSelfStreamAdded: isSelfStream ? !isSelfStream : state.isSelfStreamAdded
            };
        }
        case actionTypes.ADD_REMOTE_STREAMS_TO_LAYOUT: {
            const {streams, isSelfStream, broadcastStreams} = payload;
            const isHost = state.broadcastData?.mediaSession?.appData?.isHost;

            if (isHost) return state;

            return {
                ...state,
                broadcastData: {
                    ...state.broadcastData,
                    streams: broadcastStreams,
                    layout: {
                        ...state.broadcastData.layout,
                        streams
                    }
                },
                isSelfStreamAdded: isSelfStream
            };
        }
        case actionTypes.RECORDING_STARTED:
        case actionTypes.RECORDING_STOPPED: {
            return {
                ...state,
                isRecording: payload.isRecording
            };
        }
        default:
            return state;
    }
};

export default broadcastReducer;
