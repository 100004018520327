import {actionTypes} from './actions';

const initialState = {
    loading: false,
    allBroadcasts: [],
    userBroadcasts: []
};

const broadcastsListReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_ALL_BROADCASTS.SUCCESS: {
            return {
                ...state,
                allBroadcasts: payload,
                loading: false
            };
        }
        case actionTypes.GET_USER_BROADCASTS.SUCCESS: {
            return {
                ...state,
                userBroadcasts: payload,
                loading: false
            };
        }
        default:
            return state;
    }
};

export default broadcastsListReducer;
