import {actionTypes} from './actions';

const initialState = {
    loading: false,
    mediaInputSources: [],
    constrains: {
        audio: {
            deviceId: 'default'
        },
        video: {
            deviceId: 'default',
            width: {ideal: 1280},
            height: {ideal: 720}
        }
    },
    capture: null,
    shareScreenCapture: null,
    isVideoPaused: false,
    isAudioPaused: false,
    isScreenShared: false
};

const lobbyReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_USER_MEDIA.SUCCESS: {
            return {
                ...state,
                capture: payload,
                loading: false
            };
        }
        case actionTypes.GET_SCREEN_MEDIA.SUCCESS: {
            return {
                ...state,
                shareScreenCapture: payload,
                loading: false
            };
        }
        case actionTypes.SET_MEDIA_DEVICES_LIST: {
            return {
                ...state,
                ...payload,
                loading: false
            };
        }
        case actionTypes.TOGGLE_SELF_VIDEO: {
            return {
                ...state,
                isVideoPaused: !state.isVideoPaused
            };
        }
        case actionTypes.TOGGLE_SELF_AUDIO: {
            return {
                ...state,
                isAudioPaused: !state.isAudioPaused
            };
        }
        case actionTypes.TOGGLE_SCREEN_SHARE: {
            return {
                ...state,
                isScreenShared: payload ?? !state.isScreenShared
            };
        }
        default:
            return state;
    }
};

export default lobbyReducer;
