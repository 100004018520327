import {createSelector} from 'reselect';

const broadcast = (state) => state.broadcast;

export const getBroadcastData = createSelector(broadcast, (state) => state.broadcastData);

export const getBroadcastInstance = createSelector(broadcast, (state) => state.broadcastInstance);

export const getBroadcastLayout = createSelector(broadcast, (state) => state.broadcastData?.layout);

export const getBroadcastHostStatus = createSelector(
    broadcast,
    (state) => state.broadcastData?.mediaSession?.appData?.isHost
);

export const getBroadcastClient = createSelector(broadcast, (state) => state.client);

export const getBroadcastLoading = createSelector(broadcast, (state) => state.loading);

export const getBroadcastToken = createSelector(broadcast, (state) => state.authToken);

export const getBroadcastSelfStreamAdded = createSelector(broadcast, (state) => state.isSelfStreamAdded);

export const getBroadcastIsRecording = createSelector(broadcast, (state) => state.isRecording);
