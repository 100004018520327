import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {VideocamOffTwoTone} from '@mui/icons-material';

import {lobbySelectors} from 'store/lobby';
import {handleCanPlay} from 'services/helpers';

import {Styles} from './VideoStream.styles';

export function VideoStream() {
    const capture = useSelector(lobbySelectors.getVideoCapture);
    const isMediaCaptureError = useSelector(lobbySelectors.getMediaCaptureError);
    const selfVideoRef = useRef();

    useEffect(() => {
        if (selfVideoRef?.current) selfVideoRef.current.srcObject = capture;
    }, [capture]);

    return (
        <Styles>
            {isMediaCaptureError ? (
                <VideocamOffTwoTone className="noVideoIcon" />
            ) : (
                <video
                    className="video"
                    ref={selfVideoRef}
                    muted
                    autoPlay
                    playsInline
                    onCanPlay={() => handleCanPlay(selfVideoRef)}
                />
            )}
        </Styles>
    );
}
