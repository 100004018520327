import {createAsyncActionTypes} from 'services/redux';

const SERVICE_NAME = 'AUTH';

export const actionTypes = {
    LOG_IN: createAsyncActionTypes(SERVICE_NAME, 'Log in'),
    LOG_OUT: createAsyncActionTypes(SERVICE_NAME, 'Log out')
};

export const authLoginSuccess = (payload) => ({type: actionTypes.LOG_IN.SUCCESS, payload});

export const authLogoutSuccess = (payload) => (dispatch) => {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('accessToken');

    return dispatch({type: actionTypes.LOG_OUT.SUCCESS, payload});
};
