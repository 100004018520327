import {connect} from 'react-redux';
import {SelfVideo} from './SelfVideo';
import {lobbySelectors} from 'store/lobby';
import {broadcastSelectors} from 'store/broadcast';

export default connect((state) => ({
    isCameraOff: lobbySelectors.getIsCameraOff(state),
    shareScreenCapture: lobbySelectors.getScreenShareCapture(state),
    isScreenShared: lobbySelectors.getIsScreenShared(state),
    client: broadcastSelectors.getBroadcastClient(state),
    isHost: broadcastSelectors.getBroadcastHostStatus(state)
}))(SelfVideo);
