import {S3} from '@aws-sdk/client-s3';
import {storageBucket, storageRegion, storageAccessKey, storageSecret, cloudFrontUrl} from 'config';

const trimEnd = (s, ch) => (s[s.length - 1] === ch ? trimEnd(s.substr(0, s.length - 1), ch) : s);

const getLastPathPart = (path) => {
    path = trimEnd(path, '/');
    const lastIndex = path.lastIndexOf('/');
    return path.substr(lastIndex + 1);
};

const getListObjects = () => {
    const awsBucket = new S3({
        region: storageRegion,
        credentials: {
            accessKeyId: storageAccessKey,
            secretAccessKey: storageSecret
        },
        signatureVersion: 'v4',
        params: {Bucket: storageBucket}
    });

    return {
        async ls() {
            const result = {files: [], folders: []};

            function s3ListCheckTruncated(data) {
                result.files = result.files.concat((data.Contents || []).map((i) => i.Key));
                result.folders = result.folders.concat((data.CommonPrefixes || []).map((i) => i.Prefix));

                if (data.IsTruncated) {
                    return awsBucket.listObjectsV2({
                        Bucket: storageBucket,
                        ContinuationToken: data.NextContinuationToken
                    });
                }

                return result;
            }

            const data = await awsBucket.listObjectsV2({
                Bucket: storageBucket
            });

            return s3ListCheckTruncated(data);
        }
    };
};

export const getBucketList = () => {
    const lister = getListObjects();

    async function generate() {
        let data = await lister.ls();

        const result = [];
        data.files.forEach((file) => {
            if (file.includes('.mp4')) {
                result.push({
                    id: file,
                    title: getLastPathPart(file),
                    src: `${cloudFrontUrl}/${file}`
                });
            }
        });

        return result;
    }

    return generate();
};
