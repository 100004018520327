import {connect} from 'react-redux';
import {BroadcastsList} from './BroadcastsList';
import {broadcastsListSelectors, broadcastsListActions} from 'store/broadcastsList';
import {userSelectors} from 'store/user';

export default connect(
    (state) => ({
        allBroadcasts: broadcastsListSelectors.getAllBroadcasts(state),
        userBroadcasts: broadcastsListSelectors.getUserBroadcasts(state),
        user: userSelectors.getUserData(state)
    }),
    {
        getAllBroadcasts: broadcastsListActions.getAllBroadcasts,
        getUsersBroadcasts: broadcastsListActions.getUsersBroadcasts,
        createBroadcast: broadcastsListActions.createBroadcast,
        deleteBroadcast: broadcastsListActions.deleteBroadcast
    }
)(BroadcastsList);
