import {createTheme} from '@mui/material/styles';

import {breakpoints} from './breakpoints';
import {typography} from './typography';
import {palette} from './palette';
import {overrides} from './overrides';

export const theme = createTheme({
    breakpoints,
    typography,
    palette,
    components: overrides
});
