import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Button} from '@mui/material';

import styles from './CreateBroadcastDialog.module.scss';
import {slugify} from 'services/helpers';

export function CreateBroadcastDialog({open, onClose, onSubmit}) {
    const [slug, setSlug] = useState('');
    const [name, setName] = useState('');

    const onChange = ({target: {value}}) => {
        setName(value);
        setSlug(slugify(value));
    };

    const onCreate = () => onSubmit(slug);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: styles.dialogContainer
            }}
        >
            <DialogTitle className={styles.title}>New broadcast</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.description}>
                    Please enter the name of the new broadcast, based on which the broadcast slug will be generated
                </DialogContentText>
                <TextField
                    value={name}
                    variant="outlined"
                    type="text"
                    label="Broadcast name"
                    margin="normal"
                    className={styles.broadcsatNameInput}
                    onChange={onChange}
                    fullWidth
                />
                <TextField
                    value={slug}
                    variant="outlined"
                    type="text"
                    label="Broadcast slug"
                    margin="normal"
                    disabled
                    className={styles.broadcsatNameInput}
                    fullWidth
                />
                <div className={styles.buttonWrapper}>
                    <Button
                        onClick={onCreate}
                        variant="contained"
                        className={styles.createBroadcastsButton}
                        disabled={!slug}
                    >
                        Create
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

CreateBroadcastDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};
