import {call} from 'redux-saga/effects';
import Api from 'api';
import {handleAsyncActionSaga} from 'services/redux';
import {actionTypes} from './actions';

function* getAllBroadcasts() {
    return yield call(Api.request, {
        url: '/broadcasts'
    });
}

function* getUserBroadcasts() {
    return yield call(Api.request, {
        url: '/broadcasts/my'
    });
}

function* createBroadcast(payload) {
    return yield call(Api.request, {
        url: '/broadcasts',
        method: 'POST',
        payload
    });
}

function* deleteBroadcast(broadcastId) {
    return yield call(Api.request, {
        url: `/broadcasts/${broadcastId}`,
        method: 'DELETE'
    });
}

export default function* broadcastsListSaga() {
    yield handleAsyncActionSaga(actionTypes.GET_ALL_BROADCASTS, getAllBroadcasts);
    yield handleAsyncActionSaga(actionTypes.GET_USER_BROADCASTS, getUserBroadcasts);
    yield handleAsyncActionSaga(actionTypes.CREATE_NEW_BROADCAST, createBroadcast);
    yield handleAsyncActionSaga(actionTypes.DELETE_BROADCAST, deleteBroadcast);
}
