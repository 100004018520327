import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';

import styles from '../../BroadcastList.module.scss';

export function ListComponent({broadcasts, isUsersList, onDeleteBroadcast}) {
    const navigate = useNavigate();

    const onJoinBroadcast = (slug) => () => navigate(`/lobby/${slug}`);

    const onDelete = (id) => () => onDeleteBroadcast(id);

    return (
        <List className={styles.broadcastsList}>
            {broadcasts.map((broadcast) => (
                <ListItem key={broadcast.id} className={styles.broadcastsListItem}>
                    <ListItemText primary={broadcast.slug} />
                    <Button
                        onClick={onJoinBroadcast(broadcast.slug)}
                        variant="contained"
                        className={styles.broadcastsListButton}
                    >
                        Join
                    </Button>
                    {isUsersList && (
                        <Button
                            onClick={onDelete(broadcast.id)}
                            variant="contained"
                            className={classNames(styles.broadcastsListButton, styles.deleteButton)}
                        >
                            Delete
                        </Button>
                    )}
                </ListItem>
            ))}
        </List>
    );
}

ListComponent.propTypes = {
    broadcasts: PropTypes.array.isRequired,
    isUsersList: PropTypes.bool,
    onDeleteBroadcast: PropTypes.func
};
