import {Buttons} from './Buttons';
import {connect} from 'react-redux';
import {lobbySelectors, lobbyActions} from 'store/lobby';
import {broadcastSelectors} from 'store/broadcast';

export default connect(
    (state) => ({
        broadcastData: broadcastSelectors.getBroadcastData(state),
        localStream: lobbySelectors.getVideoCapture(state),
        shareScreenCapture: lobbySelectors.getScreenShareCapture(state),
        isScreenShared: lobbySelectors.getIsScreenShared(state),
        isMuted: lobbySelectors.getIsAudioOff(state),
        isVideoPaused: lobbySelectors.getIsCameraOff(state)
    }),
    {
        getLocalStream: lobbyActions.getLocalStream,
        getScreenShareStream: lobbyActions.getScreenShareStream,
        toggleSelfVideo: lobbyActions.toggleSelfVideo,
        toggleSelfAudio: lobbyActions.toggleSelfAudio,
        toggleScreenShareVideo: lobbyActions.toggleScreenShareVideo,
        disableScreenShare: lobbyActions.disableScreenShare
    }
)(Buttons);
