import {connect} from 'react-redux';
import {MoreMenu} from './MoreMenu';
import {broadcastSelectors, broadcastActions} from 'store/broadcast';

export default connect(
    (state) => ({
        client: broadcastSelectors.getBroadcastClient(state),
        broadcast: broadcastSelectors.getBroadcastInstance(state)
    }),
    {removeStreamFromLayout: broadcastActions.removeStreamFromLayout}
)(MoreMenu);
