import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ShareBroadcastButton from './components/ShareBroadcastButton';
import StreamUrlInput from './components/StreamUrlInput';

import {broadcastSelectors} from 'store/broadcast';

import styles from './HostControls.module.scss';

const CONTROL_VALUES = {
    RTMP: 0,
    PUSH_RTMP: 1,
    S3: 2,
    SHARE_BROADCAST: 3,
    RECORDING_BROADCAST: 4
};

export function HostControls({toggleSuccessSnackbar}) {
    const broadcastInstance = useSelector(broadcastSelectors.getBroadcastInstance);
    const isRecording = useSelector(broadcastSelectors.getBroadcastIsRecording);
    const [selectedButton, setSelectedButton] = useState(null);
    const [streamUrl, setStreamUrl] = useState('');

    const isStreamUrl =
        selectedButton === CONTROL_VALUES.RTMP ||
        selectedButton === CONTROL_VALUES.PUSH_RTMP ||
        selectedButton === CONTROL_VALUES.S3;
    const streamType = Object.keys(CONTROL_VALUES)[selectedButton];

    useEffect(() => {
        if (selectedButton !== null) {
            setTimeout(() => {
                setSelectedButton(null);
            }, 10000);
        }
    }, [selectedButton]);

    const onButtonClick = (event, newValue) => {
        setStreamUrl('');
        setSelectedButton(newValue);
    };

    const onInputChange = ({target: {value}}) => setStreamUrl(value);

    const onAddStream = useCallback(async () => {
        try {
            if (streamType === Object.keys(CONTROL_VALUES)[0]) {
                await broadcastInstance.pullRtmpStream(streamUrl);
            } else if (streamType === Object.keys(CONTROL_VALUES)[1]) {
                await broadcastInstance.pushRtmpStream(streamUrl);
            } else {
                await broadcastInstance.addS3Video(streamUrl);
            }

            setSelectedButton(null);
        } catch (error) {
            console.warn(error);
        }
    }, [streamType, streamUrl, broadcastInstance]);

    const onHandleRecording = async () => {
        try {
            if (isRecording) {
                await broadcastInstance.stopRecording();
            } else {
                await broadcastInstance.startRecording();
            }
        } catch (error) {
            console.warn(error);
        }
    };

    return (
        <Box sx={{width: '100%'}} className={styles.hostControlsContainer}>
            <BottomNavigation
                classes={{
                    root: styles.inner
                }}
                showLabels
                value={selectedButton}
                onChange={onButtonClick}
            >
                <BottomNavigationAction
                    label="Add RTMP stream"
                    icon={<VideoLibraryIcon />}
                    classes={{
                        selected: styles.selectedButton
                    }}
                />
                <BottomNavigationAction
                    label="Push RTMP stream"
                    icon={<VideoLibraryIcon />}
                    classes={{
                        selected: styles.selectedButton
                    }}
                />
                <BottomNavigationAction
                    label="Add S3 stream"
                    icon={<VideoLibraryIcon />}
                    classes={{
                        selected: styles.selectedButton
                    }}
                />
                <BottomNavigationAction
                    label="Share broadcast link"
                    icon={<ContentCopyIcon />}
                    classes={{
                        selected: styles.selectedButton
                    }}
                />
                <BottomNavigationAction
                    label="Recording broadcast"
                    icon={<FiberManualRecordIcon />}
                    classes={{
                        selected: styles.selectedButton
                    }}
                />
            </BottomNavigation>
            {selectedButton === CONTROL_VALUES.SHARE_BROADCAST && (
                <ShareBroadcastButton toggleSuccessSnackbar={toggleSuccessSnackbar} />
            )}
            {isStreamUrl && (
                <StreamUrlInput
                    streamType={streamType}
                    value={streamUrl}
                    onChange={onInputChange}
                    onButtonClick={onAddStream}
                />
            )}
            {selectedButton === CONTROL_VALUES.RECORDING_BROADCAST && (
                <Button className={styles.recordingButton} onClick={onHandleRecording} variant="contained">
                    {`${isRecording ? 'Stop' : 'Start'} recording`}
                </Button>
            )}
        </Box>
    );
}

HostControls.propTypes = {
    toggleSuccessSnackbar: PropTypes.func.isRequired
};
