import {styled} from '@mui/system';

export const Styles = styled('div')(() => ({
    '.buttons': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 50px)',
        gridColumnGap: 50,
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        zIndex: 200,
        bottom: 48,
        left: 0
    },
    '.button': {
        color: '#fff',
        cursor: 'pointer',
        fontSize: '45px',
        background: 'transparent',
        border: 'none'
    },
    '.icon': {
        background: 'rgba(0, 0, 0, 0.16)',
        backdropFilter: 'blur(56px)',
        borderRadius: '50%',
        position: 'relative',
        fontSize: '45px',
        padding: '5px'
    },
    '.buttonEndCall': {
        color: 'red'
    }
}));
