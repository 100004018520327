import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/system';
import {CircularProgress} from '@mui/material';

const Styles = styled('div')({
    '.root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 70px)'
    },
    '.spinner': {
        color: '#fff'
    }
});

const Spinner = ({size}) => (
    <Styles>
        <div className="root">
            <CircularProgress className="spinner" size={size} />
        </div>
    </Styles>
);

Spinner.propTypes = {size: PropTypes.number};

Spinner.defaultProps = {size: 80};

export default Spinner;
