import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {styled} from '@mui/system';

import Header from './components/Header';
import Lobby from 'pages/lobby';
import Broadcast from 'pages/broadcast';
import BroadcastList from 'pages/broadcastsList';
import RecordingsList from 'pages/recordingsList';

const Styles = styled('div')(({theme}) => ({
    color: theme.palette.text.primary,
    height: '100vh'
}));

function App() {
    return (
        <Styles className="App">
            <Header />
            <Routes>
                <Route path="/" element={<BroadcastList />} />
                <Route path="/recordings" element={<RecordingsList />} />
                <Route path="/lobby" element={<Lobby />}>
                    <Route path="/lobby/:broadcastId" element={<Lobby />} />
                </Route>
                <Route path="/broadcast" element={<Broadcast />}>
                    <Route path="/broadcast/:broadcastId" element={<Broadcast />} />
                </Route>
            </Routes>
        </Styles>
    );
}

export default App;
