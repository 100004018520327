import {Client} from 'streaming-client-sdk';
import {wsHost} from 'config';

export const calculateGridSize = (total) => {
    if (!total) total = 1;
    const xSize = Math.ceil(Math.sqrt(total));
    const ySize = xSize * (xSize - 1) >= total ? xSize - 1 : xSize;

    return {
        width: `${100 / xSize}%`,
        height: `${100 / ySize}%`
    };
};

export const createClient = async ({authToken}) => {
    try {
        const client = new Client({authToken, host: wsHost});

        await client.init();

        return client;
    } catch (error) {
        console.warn(error);
    }
};

export const normalizeStreamMember = (stream) => {
    const name = stream?.user?.displayName?.split(' ');
    return {
        givenName: name && name[0],
        familyName: name?.length > 1 ? name.pop() : '',
        avatarUrl: stream?.user?.avatarUrl
    };
};

export const slugify = (str) =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
