import {styled} from '@mui/system';

export const Styles = styled('div')(({theme}) => ({
    '& .video': {
        width: 500,
        height: 375,
        borderRadius: '6px'
    },
    '& .bottomWrapper': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    '& .title': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.0015em',
        margin: '0 25px 0 0'
    },
    '& .deviceSelect': {
        width: 180,
        '& > .MuiInputBase-root': {
            height: '100%'
        }
    },
    '&. selectedValue': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        paddingTop: 0,
        paddingBottom: 0
    },
    '& .arrowDownIcon': {
        color: theme.palette.text.primary
    },
    '& .noVideoIcon': {
        width: 150,
        height: 150,
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main
    }
}));
