import {createAsyncActionTypes, createActionType, createAsyncAction} from 'services/redux';
import {createClient} from 'services/helpers';
import {lobbyActions} from '../lobby';
import {BROADCAST_LAYOUT_STREAM_TYPES} from 'constants';

const SERVICE_NAME = 'BROADCAST';

export const actionTypes = {
    BROADCAST_AUTH: createAsyncActionTypes(SERVICE_NAME, 'Authorization'),
    BROADCAST_GUEST_AUTH: createAsyncActionTypes(SERVICE_NAME, 'Authorization guest'),
    CREATE_BROADCAST: createAsyncActionTypes(SERVICE_NAME, 'Create'),
    CLEAR_STATE: createActionType(SERVICE_NAME, 'Clear state'),
    ADD_CONSUMER: createActionType(SERVICE_NAME, 'Add consumer'),
    CLOSE_CONSUMER: createActionType(SERVICE_NAME, 'Close consumer'),
    PAUSE_CONSUMER: createActionType(SERVICE_NAME, 'Pause consumer'),
    RESUME_CONSUMER: createActionType(SERVICE_NAME, 'Resume consumer'),
    ADD_STREAM: createActionType(SERVICE_NAME, 'Add stream'),
    REMOVE_STREAM: createActionType(SERVICE_NAME, 'Remove stream'),
    JOIN_BROADCAST: createActionType(SERVICE_NAME, 'Join'),
    SET_BROADCAST_INSTANCE: createActionType(SERVICE_NAME, 'Set broadcast instance'),
    ADD_STREAM_TO_LAYOUT: createActionType(SERVICE_NAME, 'Add to layout'),
    ROMOVE_STREAM_FROM_LAYOUT: createActionType(SERVICE_NAME, 'Remove from layout'),
    ADD_REMOTE_STREAMS_TO_LAYOUT: createActionType(SERVICE_NAME, 'Add remote streams to layout'),
    RECORDING_STARTED: createActionType(SERVICE_NAME, 'Recording in progress'),
    RECORDING_STOPPED: createActionType(SERVICE_NAME, 'Recording stopped')
};

const createBroadcastRequest = () => ({type: actionTypes.CREATE_BROADCAST.REQUEST});
const createBroadcastSuccess = (payload) => ({type: actionTypes.CREATE_BROADCAST.SUCCESS, payload});
const createBroadcastFailure = () => ({type: actionTypes.CREATE_BROADCAST.FAILURE});

const createBroadcast = (broadcastSlug) => async (dispatch, getState) => {
    const {
        broadcast: {authToken}
    } = getState();
    try {
        dispatch(createBroadcastRequest());

        const client = await createClient({authToken});

        await client.authenticate();

        const broadcastData = broadcastSlug
            ? await client.getBroadcastBySlug(broadcastSlug)
            : await client.createBroadcast();

        dispatch(createBroadcastSuccess({client, broadcastData}));
    } catch (error) {
        dispatch(createBroadcastFailure());
    }
};

const clearState = (isReconnect) => async (dispatch, getState) => {
    const {
        lobby: {capture, constrains},
        broadcast: {broadcastInstance, client}
    } = getState();

    try {
        if (!isReconnect) {
            broadcastInstance && (await broadcastInstance.destroy());
            client && client.close();
        }

        capture.getTracks().forEach((track) => track.stop());
    } catch (error) {
        console.warn(error);
    }

    dispatch({
        type: actionTypes.CLEAR_STATE,
        payload: isReconnect
    });

    isReconnect && dispatch(lobbyActions.getLocalStream(constrains, isReconnect));
};

const addConsumer = ({consumer, streamId}) => ({
    type: actionTypes.ADD_CONSUMER,
    payload: {consumer, streamId}
});

const closeConsumer = ({consumerId, streamId}) => ({
    type: actionTypes.CLOSE_CONSUMER,
    payload: {consumerId, streamId}
});

const pauseConsumer = ({consumerId, streamId}) => ({
    type: actionTypes.PAUSE_CONSUMER,
    payload: {consumerId, streamId}
});

const resumeConsumer = ({consumerId, streamId}) => ({
    type: actionTypes.RESUME_CONSUMER,
    payload: {consumerId, streamId}
});

const addStream = (payload) => ({
    type: actionTypes.ADD_STREAM,
    payload
});

const removeStream = (payload) => ({
    type: actionTypes.REMOVE_STREAM,
    payload
});

const joinBroadcast = (payload) => ({
    type: actionTypes.JOIN_BROADCAST,
    payload
});

const setBroadcastInstance = (payload) => ({
    type: actionTypes.SET_BROADCAST_INSTANCE,
    payload
});

const addStreamToLayout =
    ({streamId, isSelfStream}) =>
    async (dispatch, getState) => {
        const {
            broadcast: {broadcastInstance}
        } = getState();
        try {
            await broadcastInstance.addStreamToLayout(streamId);

            dispatch({
                type: actionTypes.ADD_STREAM_TO_LAYOUT,
                payload: {streamId, isSelfStream}
            });
        } catch (error) {
            console.warn('Add stream to layout failed:%o', error);
        }
    };

const kickStream =
    ({streamId, type}) =>
    async (dispatch, getState) => {
        const {
            broadcast: {broadcastInstance}
        } = getState();
        try {
            switch (type) {
                case BROADCAST_LAYOUT_STREAM_TYPES.IngressRTMP: {
                    await broadcastInstance.removeIngressRTMPStream(streamId);
                    break;
                }
                case BROADCAST_LAYOUT_STREAM_TYPES.WebRTC: {
                    await broadcastInstance.kickParticipant(streamId);
                    break;
                }
                case BROADCAST_LAYOUT_STREAM_TYPES.S3: {
                    await broadcastInstance.removeS3Video(streamId);
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            console.warn('Add stream to layout failed:%o', error);
        }
    };

const removeStreamFromLayout =
    ({streamId, isSelfStream}) =>
    async (dispatch, getState) => {
        const {
            broadcast: {broadcastInstance}
        } = getState();
        try {
            await broadcastInstance.removeStreamFromLayout(streamId);

            dispatch({
                type: actionTypes.ROMOVE_STREAM_FROM_LAYOUT,
                payload: {streamId, isSelfStream}
            });
        } catch (error) {
            console.warn('Remove stream from layout failed:%o', error);
        }
    };

const addRemoteStreamsToLayout = (payload) => ({
    type: actionTypes.ADD_REMOTE_STREAMS_TO_LAYOUT,
    payload
});

const recordingStarted = () => ({
    type: actionTypes.RECORDING_STARTED,
    payload: {isRecording: true}
});

const recordingStopped = () => ({
    type: actionTypes.RECORDING_STOPPED,
    payload: {isRecording: false}
});

export const actions = {
    broadcastAuth: createAsyncAction(actionTypes.BROADCAST_AUTH, 'enter broadcast'),
    broadcastGuestAuth: createAsyncAction(actionTypes.BROADCAST_GUEST_AUTH, 'guest enter broadcast'),
    createBroadcast,
    createBroadcastFailure,
    addConsumer,
    closeConsumer,
    pauseConsumer,
    resumeConsumer,
    addStream,
    removeStream,
    joinBroadcast,
    setBroadcastInstance,
    clearState,
    addStreamToLayout,
    kickStream,
    removeStreamFromLayout,
    addRemoteStreamsToLayout,
    recordingStarted,
    recordingStopped
};
