import axios from 'axios';
import {toast} from 'react-toastify';
import {apiUrl} from 'config';
import store from 'store';
import {actions} from 'store/loader';

class Api {
    constructor(baseURL) {
        this.adapter = axios.create({
            baseURL
        });
    }

    request = async ({
        url,
        method = 'get',
        payload,
        loading,
        headers = {},
        errorObj = {show: false, message: ''},
        params,
        responseType = 'json'
    }) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (loading === undefined) store.dispatch(actions.setLoaderOn());

            const request = {
                url,
                method,
                data: payload,
                params,
                headers: {
                    ...headers
                },
                responseType
            };

            if (accessToken) {
                this.adapter.defaults.headers = {
                    Authorization: `Bearer ${accessToken}`
                };
            }

            const response = await this.adapter.request(request).catch(({response}) => {
                this.handleError(response, request, errorObj);
                throw response;
            });

            if (store.getState().loader > 0) store.dispatch(actions.setLoaderOff());
            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    setAccessToken = (token) => {
        localStorage.setItem('accessToken', token);
    };

    setRefreshToken = (token) => {
        // set expiration date to 30 days
        const date = new Date();
        const minutes = 60;
        date.setTime(date.getTime() + minutes * 24 * (59400 * 30));

        localStorage.setItem('accessToken', token);
    };

    handleError = async (err, request, customError) => {
        if (store.getState().loader > 0) store.dispatch(actions.setLoaderOff());

        if (err && err.status === 401) {
            // store.dispatch(actions.logoutUser());
            localStorage.removeItem('accessToken');
        }

        if (err?.status === 500) {
            toast.error(typeof err?.data?.message === 'string' ? err?.data?.message : 'Server Error');
        } else if (customError?.show) {
            const serverErrorMessage = typeof err?.data?.message === 'string' ? err?.data?.message : 'Server Error';
            const errorMessage = customError.message ? customError.message : serverErrorMessage;

            toast.error(errorMessage);
        }
    };
}

export default new Api(apiUrl);
