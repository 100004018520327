export const envList = {
    develop: 'develop',
    stage: 'stage',
    production: 'production'
};

export const env = process.env.REACT_APP_ENV || envList.develop;
export const apiUrl = process.env.REACT_APP_API_URL || '';
export const wsHost = process.env.REACT_APP_WS_HOST || '';
export const googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_ID || '';
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET || '';
export const storageRegion = process.env.REACT_APP_STORAGE_REGION || '';
export const storageAccessKey = process.env.REACT_APP_STORAGE_KEY || '';
export const storageSecret = process.env.REACT_APP_STORAGE_SECRET || '';
export const cloudFrontUrl = process.env.REACT_APP_CLOUD_FRONT_URL || '';
