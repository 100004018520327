import React, {useRef, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {MEDIA_TYPES} from 'constants';

import VideoAvatar from '../../../Avatar';

import styles from './GuestStream.module.scss';
import {normalizeStreamMember} from 'services/helpers';

export function GuestStream({stream, onAddStreamToLayout, onRemoveStream}) {
    const [videoTrack, setVideoTrack] = useState(null);
    const [isVideoPaused, setIsVideoPaused] = useState(false);
    const videoEl = useRef();

    const enableTrack = useCallback(
        (newVideoTrack) => {
            if (videoTrack === newVideoTrack) return;

            if (newVideoTrack) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(newVideoTrack);
                videoEl.current.srcObject = mediaStream;
                videoEl.current.id = newVideoTrack.id;
                videoEl.current.play().catch((error) => console.warn('videoEl.play() failed:%o', error));
            } else {
                videoEl.current.srcObject = null;
            }
        },
        [videoTrack]
    );

    const getVideoTrack = useCallback(() => {
        const consumersArray = stream.consumers;

        if (!consumersArray) return;

        const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.video);
        const shareConsumer = consumersArray.find(
            (consumer) => consumer.appData.type === MEDIA_TYPES.consumerScreenShare
        );

        const videoTrack = shareConsumer ? shareConsumer?.track : videoConsumer?.track;

        setIsVideoPaused(!videoConsumer);
        setVideoTrack(videoTrack);
        enableTrack(videoTrack);
    }, [stream, enableTrack]);

    useEffect(() => {
        const videoRef = videoEl.current;

        getVideoTrack();
        return () => {
            if (videoRef) {
                videoRef.oncanplay = null;
                videoRef.onplay = null;
                videoRef.onpause = null;
            }
        };
    }, [getVideoTrack]);

    useEffect(() => {
        const consumersArray = stream?.consumers;

        if (!stream || !consumersArray) {
            return;
        }
        const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.video);

        setIsVideoPaused(!videoConsumer);
    }, [stream]);

    const streamMember = normalizeStreamMember(stream);

    return (
        <div className={styles.videoWrapper}>
            <video
                className={classNames(styles.video, {
                    [styles.videoPaused]: isVideoPaused
                })}
                ref={videoEl}
                autoPlay
                playsInline
                muted
                controls={false}
            />
            {isVideoPaused && <VideoAvatar text={'name'} member={streamMember} isMinified />}
            <div className={styles.buttonWrapper}>
                <Button onClick={onAddStreamToLayout(stream)} className={styles.addButton}>
                    <AddCircleIcon className={styles.addIcon} />
                </Button>
                <Button onClick={onRemoveStream(stream)} className={styles.addButton}>
                    <RemoveCircleIcon className={styles.addIcon} />
                </Button>
            </div>
        </div>
    );
}

GuestStream.propTypes = {
    stream: PropTypes.object.isRequired,
    onAddStreamToLayout: PropTypes.func.isRequired,
    onRemoveStream: PropTypes.func.isRequired
};
