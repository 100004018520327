import {WAIT_FOR_ACTION, ERROR_ACTION, CALLBACK_ARGUMENT, CALLBACK_ERROR_ARGUMENT} from 'redux-wait-for-action';

const ACTION_TYPE_OPTIONS = {
    REQUEST: 'Request',
    SUCCESS: 'Success',
    FAILURE: 'Failure'
};

export const createActionType = (serviceName, action) => `[${serviceName}]: ${action}`;

export const createAction = (type, payload) => () => ({
    type,
    payload
});

export const createAsyncActionTypes = (service, operation) => ({
    REQUEST: createActionType(`${service}/${operation}`, ACTION_TYPE_OPTIONS.REQUEST),
    SUCCESS: createActionType(`${service}/${operation}`, ACTION_TYPE_OPTIONS.SUCCESS),
    FAILURE: createActionType(`${service}/${operation}`, ACTION_TYPE_OPTIONS.FAILURE)
});

export const createAsyncAction = (actionTypes, service, transformParams = (params) => params) => {
    const actionCreator = (params, callback) => ({
        type: actionTypes.REQUEST,
        service,
        payload: transformParams(params),
        onSuccess: callback
    });

    actionCreator.promisify = (...args) => ({
        ...actionCreator(...args),
        [WAIT_FOR_ACTION]: actionTypes.SUCCESS,
        [ERROR_ACTION]: actionTypes.FAILURE,
        [CALLBACK_ARGUMENT]: (action) => action.payload,
        [CALLBACK_ERROR_ARGUMENT]: (action) => action
    });

    return actionCreator;
};
