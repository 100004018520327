import React from 'react';
import PropTypes from 'prop-types';

import styles from './ListWrapper.module.scss';

export function ListWrapper({children}) {
    return <div className={styles.listWrapper}>{children}</div>;
}

ListWrapper.propTypes = {
    children: PropTypes.node
};
