import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    CallEnd as CallEndIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon
} from '@mui/icons-material';

import {BUTTON_TYPES} from 'constants';

import {Styles} from './Buttons.styles';

export const Buttons = ({
    withCallEndIcon,
    onStopCall,
    broadcastData: {mediaSession},
    isScreenShared,
    isMuted,
    isVideoPaused,
    getLocalStream,
    getScreenShareStream,
    toggleSelfVideo,
    toggleSelfAudio,
    toggleScreenShareVideo,
    disableScreenShare
}) => {
    const onToggleSound = () => {
        toggleSelfAudio();
        mediaSession.isMuted ? mediaSession.unmuteMic() : mediaSession.muteMic();
    };

    const onToggleVideo = () => {
        toggleSelfVideo();

        isVideoPaused ? getLocalStream() : mediaSession.disableVideo();
    };

    const onShareScreen = () => {
        toggleScreenShareVideo();

        isScreenShared ? disableScreenShare() : getScreenShareStream();
    };

    const buttons = [
        {
            type: BUTTON_TYPES.sound,
            onClick: onToggleSound
        },
        {
            type: BUTTON_TYPES.video,
            onClick: onToggleVideo
        },
        {
            type: BUTTON_TYPES.shareScreen,
            onClick: onShareScreen
        },
        {
            type: BUTTON_TYPES.stopCall,
            btnClassName: 'buttonEndCall',
            onClick: withCallEndIcon ? onStopCall : false
        }
    ];

    const renderBtnIcon = (type) => {
        let Icon;
        switch (type) {
            case BUTTON_TYPES.shareScreen: {
                Icon = !isScreenShared ? ScreenShareIcon : StopScreenShareIcon;
                break;
            }
            case BUTTON_TYPES.sound: {
                Icon = !isMuted ? MicIcon : MicOffIcon;
                break;
            }
            case BUTTON_TYPES.video: {
                Icon = !isVideoPaused ? VideocamIcon : VideocamOffIcon;
                break;
            }
            case BUTTON_TYPES.stopCall:
            default: {
                Icon = CallEndIcon;
                break;
            }
        }
        return <Icon className="icon" />;
    };

    return (
        <Styles>
            <div className="buttons">
                {buttons.map((item) => {
                    if (item.type === BUTTON_TYPES.stopCall && !withCallEndIcon) return null;

                    return (
                        <button
                            key={item.type}
                            className={classNames('button', item.btnClassName)}
                            onClick={item.onClick}
                        >
                            {renderBtnIcon(item.type)}
                        </button>
                    );
                })}
            </div>
        </Styles>
    );
};

Buttons.propTypes = {
    withCallEndIcon: PropTypes.bool,
    onStopCall: PropTypes.func,
    broadcastData: PropTypes.object,
    localStream: PropTypes.object,
    shareScreenCapture: PropTypes.object,
    isScreenShared: PropTypes.bool,
    isMuted: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    getLocalStream: PropTypes.func,
    getScreenShareStream: PropTypes.func,
    toggleSelfVideo: PropTypes.func,
    toggleSelfAudio: PropTypes.func,
    toggleScreenShareVideo: PropTypes.func,
    disableScreenShare: PropTypes.func
};

Buttons.defaultProps = {
    withCallEndIcon: true
};
